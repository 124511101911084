{
  "Welcome to": " Welcome to",
  "UI Elements": "UI Elements",
  "Forms & Tables": "Forms & Tables",
  "Pages": "Pages",
  "Charts & Maps": "Charts & Maps",
  "Others": "Others",
  "Typography": "Typography",
  "Cards": "Cards",
  "Basic": "Basic",
  "Advance": "Advance",
  "Widgets": "Widgets",
  "Components": "Components",
  "Alert": "Alert",
  "Avatar": "Avatar",
  "Badge": "Badge",
  "Button": "Button",
  "Calendar": "Calendar",
  "Image": "Image",
  "Pagination": "Pagination",
  "Progress Circular": "Progress Circular",
  "Progress Linear": "Progress Linear",
  "Autocomplete": "Autocomplete",
  "Tooltip": "Tooltip",
  "Slider": "Slider",
  "Date Time Picker": "Date Time Picker",
  "Select": "Select",
  "Select Options": "Select Options",
  "Switch": "Switch",
  "Checkbox": "Checkbox",
  "Radio": "Radio",
  "Textarea": "Textarea",
  "Rating": "Rating",
  "excel": "excel",
  "pdf": "pdf",
  "File Input": "File Input",
  "Form Layout": "Form Layout",
  "Form Validation": "Form Validation",
  "Charts": "Charts",
  "Apex Chart": "Apex Chart",
  "Cluster Name": "Cluster Name",
  "Total MC Books": "Total MC Books",
  "Total Confirmations": "Total Confirmations",
  "Total MC Books Receivable": "Total MC Books Receivable",
  "Total Balance": "Total Balance",
  "Chartjs": "Chartjs",
  "Clusters VS Routes Report": "Clusters VS Routes Report",
  "All Transactions Report": "All Transactions Report",
  "Search Whatsapp Log": "Search Whatsapp Log",
  "Whatsapp' key": "Whatsapp' key",
  "Export To PDF": "Export To PDF",
  "Export To Excel": "Export To Excel",
  "Account Settings": "Account Settings",
  "User Profile": "User Profile",
  "FAQ": "FAQ",
  "Total": "Total",
  "Dialog Examples": "Dialog Examples",
  "Pricing": "Pricing",
  "Clusters vs Routes Report": "Clusters vs Routes Report",
  "OK": "OK",
  "New": "New",
  "Old": "Old",
  "List": "List",
  "Edit": "Edit",
  "Nav Levels": "Nav Levels",
  "Level 2.1": "Level 2.1",
  "Level 2.2": "Level 2.2",
  "Level 3.1": "Level 3.1",
  "Level 3.2": "Level 3.2",
  "Raise Support": "Raise Support",
  "Documentation": "Documentation",
  "Dashboards": "Dashboards",
  "Analytics": "Analytics",
  "Apps & Pages": "Apps & Pages",
  "ID": "ID",
  "Email": "Email",
  "Phone": "Phone",
  "Created Time": "Created Time",
  "Updated Time": "Updated Time",
  "Action": "Action",
  "Chat": "Chat",
  "Invoice": "Invoice",
  "Preview": "Preview",
  "Select Template": "Select Template",
  "Add": "Add",
  "Add New": "Add New",
  "Create": "Create",
  "Submit": "Submit",
  "Search": "Search",
  "Status": "Status",
  "User": "User",
  "View": "View",
  "Repeat": "Repeat",
  "Send": "Send",
  "Download": "Download",
  "Login v1": "Login v1",
  "Login v2": "Login v2",
  "Login": "Login",
  "Register v1": "Register v1",
  "Register v2": "Register v2",
  "Register": "Register",
  "Password": "Password",
  "Confirm Password": "Confirm Password",
  "Forget Password v1": "Forget Password v1",
  "Forget Password v2": "Forget Password v2",
  "Forgot Password v1": "Forgot Password v1",
  "Forgot Password v2": "Forgot Password v2",
  "Forgot Password": "Forgot Password",
  "Reset Password v1": "Reset Password v1",
  "Reset Password v2": "Reset Password v2",
  "Reset Password": "Reset Password",
  "Generate Password": "Generate & Send Password",
  "Change Password": "Change Password",
  "Current Password": "Current Password",
  "New Password": "New Password",
  "Confirm New Password": "Confirm New Password",
  "Password Requirements": "Password Requirements",
  "Details": "Details",
  "Contact": "Contact",
  "Landline No": "Landline No.",
  "Miscellaneous": "Miscellaneous",
  "Coming Soon": "Coming Soon",
  "Not Authorized": "Not Authorized",
  "Under Maintenance": "Under Maintenance",
  "Error": "Error",
  "Statistics": "Statistics",
  "Actions": "Actions",
  "Access Control": "Access Control",
  "User Interface": "User Interface",
  "CRM": "CRM",
  "eCommerce": "eCommerce",
  "Icons": "Icons",
  "Chip": "Chip",
  "Dialog": "Dialog",
  "Expansion Panel": "Expansion Panel",
  "Combobox": "Combobox",
  "Textfield": "Textfield",
  "Range Slider": "Range Slider",
  "Menu": "Menu",
  "Snackbar": "Snackbar",
  "Tabs": "Tabs",
  "Form Elements": "Form Elements",
  "Form Layouts": "Form Layouts",
  "Authentication": "Authentication",
  "Page Not Found - 404": "Page Not Found - 404",
  "Not Authorized - 401": "Not Authorized - 401",
  "Server Error - 500": "Server Error - 500",
  "2": "2",
  "Forms": "Forms",
  "Timeline": "Timeline",
  "Disabled Menu": "Disabled Menu",
  "Help Center": "Help Center",
  "Verify Email": "Verify Email",
  "Verify Email v1": "Verify Email v1",
  "Verify Email v2": "Verify Email v2",
  "Two Steps": "Two Steps",
  "Two Steps v1": "Two Steps v1",
  "Two Steps v2": "Two Steps v2",
  "Custom Input": "Custom Input",
  "Extensions": "Extensions",
  "Tour": "Tour",
  "Register Multi-Steps": "Register Multi-Steps",
  "Wizard Examples": "Wizard Examples",
  "Checkout": "Checkout",
  "Create Deal": "Create Deal",
  "Property Listing": "Property Listing",
  "Roles & Permissions": "Roles & Permissions",
  "Update User": "Update User",
  "Update": "Update",
  "Update View": "Update & View",
  "Edit Profile": "Edit Profile",
  "Role": "Role",
  "Simple Table": "Simple Table",
  "Tables": "Tables",
  "Data Table": "Data Table",
  "Permissions": "Permissions",
  "Apps": "Apps",
  "Misc": "Misc",
  "Remember me": "Remember me",
  "Wizard Pages": "Wizard Pages",
  "Active": "Active",
  "Archive": "Archive",
  "Inactive": "Inactive",
  "Delete": "Delete",
  "Deleted": "Deleted",
  "All": "All",
  "of": "of",
  "Export to Excel": "Export to Excel",
  "Cluster Name": "Cluster Name",
  "Delete Dialog": "Are you sure you want to delete this item?",
  "Active Dialog": "Are you sure you want to",
  "Save changes": "Save changes",
  "Cancel": "Cancel",
  "Reset": "Reset",
  "Account Security": "Account Security",
  "Display Name": "Display Name",
  "Clusters": "Clusters",
  "Routes": "Routes",
  "Materials": "Pref. Materials / Products",
  "Industries": "Industries",
  "Conditions": "Conditions",
  "Load Board": "Load Board",
  "Truck Board": "Truck Board",
  "Remarks": "Remarks",
  "Archived": "Archived",
  "Valid From": "Valid From",
  "Valid To": "Valid To",
  "Description": "Description",
  "Add New Trucker": "Add New Trucker",
  "Edit Trucker": "Edit Trucker",
  "Code": "Code",
  "Has expiry": "Has expiry",
  "Is public": "Is public",
  "Primary Documents": "Primary Documents",
  "Others Documents": "Others Documents",
  "Is primary": "Is primary",
  "Content": "Content",
  "Templates Type": "Templates Type",
  "Templates": "Templates",
  "sub_category": "Sub Category",
  "Toaster Error": "Something went wrong!",
  "Approve": "Approve",
  "Reject": "Reject",
  "Expired": "Expired",
  "SMS": "SMS",
  "WhatsApp": "WhatsApp",
  "Dynamic": "Dynamic",
  "Static": "Static",
  "Profile": "Profile",
  "Category": "Category",
  "industry": "industry",
  "message": "MESSAGE",
  "India Bulk SMS": "India Bulk SMS",
  "Bulk SMS Hyderabad": "Bulk SMS Hyderabad",
  "Business Information": "Business Information",
  "Business Personal": "Business Personal",
  "Basic Information": "Basic Information",
  "View Logs": "View Logs",
  "Document": "Document",
  "MSG91": "MSG91",
  "Name": "Name",
  "Section": "Section",
  "Remark": "Remark",
  "Template Type": "Template Type",
  "Provider": "Provider",
  "Save": "Save",
  "Save & Next": "Save & Next",
  "Update & Next": "Update & Next",
  "Documents": "Documents",
  "Approved": "Approved",
  "Rejected": "Rejected",
  "Expiring": "Expiring",
  "Pending": "Pending",
  "Driver": "Driver",
  "Yes": "Yes",
  "No": "No",
  "Okay": "Okay",
  "Active Documents": "Active Documents",
  "Archived Documents": "Archived Documents",
  "Close": "Close",
  "Save & Add New": "Save & Add New",
  "Repeat Offer": "Repeat Offer",
  "Primary Contact": "Primary Contact",
  "Alternative Contact": "Alternative Contact",
  "Required": "This field is required",
  "Confirmations": "Confirmations",
  "Send SMS": "Send SMS",
  "Send Whatsapp": "Send Whatsapp",
  "MC-book": "MC book",
  "Book": "Book",
  "Ledger": "Ledger",
  "Add Journal Entry": "Add Journal Entry",
  "Opening Balance": "Opening Balance",
  "Closing Balance": "Closing Balance",
  "Total Freight": "Total Freight",
  "Per Unit": "Per Unit",
  "Per Ton": "Per Ton",
  "PDF": "PDF",
  "Excel": "Excel",
  "confirmation single": "confirmation",
  "Confirmation": "Confirmation",
  "Truck": "Truck",
  "Branch": "Branch",
  "Employee": "Employee",
  "In process": "In process",
  "Available": "Available",
  "ALL": "ALL",
  "MC Books": "MC Books",
  "Dashboard": "Dashboard",
  "Truck Boards": "Truck Boards",
  "Load Boards": "Load Boards",
  "Truckers": "Truckers",
  "Loaders": "Loaders",
  "Users": "Users",
  "SMS Templates": "SMS Templates",
  "Masters": "Masters",
  "Banners": "Banners",
  "Add Banner": "Add Banner",
  "Search Loader": "Search Loader",
  "Payment Information": "Payment Information",
  "Freight Method & Structure": "Freight Method & Structure",
  "Trucker Information": "Trucker Information",
  "Loader Information": "Loader Information",
  "Add Loading Point": "Add Loading Point",
  "Unloading Charges": "Unloading Charges",
  "Quick Confirmation": "Quick Confirmation",
  "Add Unloading Point": "Add Unloading Point",
  "Loading Charges": "Loading Charges",
  "Freight Information": "Freight Information",
  "Total Amount": "Total Amount",
  "Landline No.": "Landline No.",
  "Mobile No. (Primary)": "Mobile No. (Primary)",
  "Address": "Address",
  "GST No.": "GST No.",
  "Email Address": "Email Address",
  "Designation": "Designation",
  "Full Name": "Full Name",
  "Capacity": "Capacity",
  "Area": "Area",
  "Payment Gateway": "Payment Gateway",
  "A/c Transfer": "A/c Transfer",
  "Total Receivable": "Total Receivable",
  "Total Received": "Total Received",
  "Total Payable": "Total Payable",
  "Total Paid": "Total Paid",
  "Balance": "Balance",
  "Business Name": "Business Name",
  "Next": "Next",
  "Payment Method": "Payment Method",
  "Freight": "Freight",
  "PR": "PR",
  "OR": "OR",
  "POD Amount": "POD Amount",
  "To Pay Amount": "To Pay Amount",
  "Advance Amount": "Advance Amount",
  "Freight Method": "Freight Method",
  "Unloading Points": "Unloading Points",
  "Loading Points": "Loading Points",
  "Notes": "Notes",
  "To Pay": "To Pay",
  "Payment Structure": "Payment Structure",
  "Loading Date": "Loading Date",
  "Load Information": "Load Information",
  "Previous": "Previous",
  "Is Public": "Is Public",
  "Driver Details": "Driver Details",
  "Offer Details": "Offer Details",
  "Search Trucker": "Search Trucker",
  "Bank Accounts": "Bank Accounts",
  "Payment Mode": "Payment Mode",
  "MC Book": "MC Book",
  "Operators": "Operators",
  "Mobile No.": "Mobile No.",
  "Operator": "Operator",
  "Load Operator": "Load Operator",
  "Truck Operator": "Truck Operator",
  "Accounts Operator": "Accounts Operator",
  "Choose files": "Choose files",
  "Latest Confirmations": "Latest Confirmations",
  "Cluster View": "Most no. of Confirmations by Cluster",
  "Route View": "Most no. of Confirmations by Routes",
  "Loads": "Loads",
  "Employees": "Employees",
  " Loading Points": " Loading Points",
  " Unloading Points": " Unloading Points",
  "Tyres": "Tyres",
  "Load ID": "Load ID",
  "Loader ID": "Loader ID",
  "Trading Name": "Trading Name",
  "Available Area": "Available Area",
  "Industry": "Industry",
  "Phones": "Phones",
  "Branches": "Branches",
  "Products": "Products",
  "Primary Name": "Primary Name",
  "Offer ID": "Offer ID",
  "Trucker ID": "Trucker ID",
  "Preferred Routes": "Preferred Routes",
  "Preferred Materials": "Pref. Materials / Products",
  "Preferred Conditions": "Preferred Conditions",
  "Owner Name": "Owner Name",
  "Owner Mobile No.": "Owner Mobile No.",
  "Driver Name": "Driver Name",
  "Driver Mobile No.": "Driver Mobile No.",
  "Driver License No.": "Driver License No.",
  "Engine No.": "Engine No.",
  "Chassis No.": "Chassis No.",
  "POD Documents": "POD Documents",
  "Preferred Name": "Preferred Name",
  "Preferred Contact No.": "Preferred Contact No.",
  "Operators / Admin": "Operators / Admin",
  "Total Confirmed Loads": "Total Confirmed Loads",
  "Total Loaders": "Total Loaders",
  "Total Truckers": "Total Truckers",
  "Total Trucks": "Total Trucks",
  "CNF ID": "CNF ID",
  "# Loading Points": "# Loading Points",
  "# Unloading Points": "# Unloading Points",
  "Truck No.": "Truck No.",
  "Freight (FR + OR)": "Freight (FR + OR)",
  "Timestamp": "Timestamp",
  "Loader Info.": "Loader Info.",
  "Trucker Info.": "Trucker Info.",
  "Daily": "Daily",
  "Weekly": "Weekly",
  "Monthly": "Monthly",
  "Yearly": "Yearly",
  "Date": "Date",
  "Confirmation Link": "Confirmation Link",
  "Confirmation URL": "Confirmation URL",
  "SMS Sent Report": "SMS Sent Report",
  "In Process": "In Process",
  "Confirmed": "Confirmed",
  "Completed": "Completed",
  "Route": "Route",
  "Paid To": "Paid To",
  "Transaction Date": "Transaction Date",
  "UTR No.": "UTR No.",
  "Unverified": "Unverified",
  "Personal Information": "Personal Information",
  "Add New Loader": "Add New Loader",
  "Generate Email": "Generate Email",
  "Is Primary": "Is Primary",
  "Notifications": "Notifications",
  "Mark as Read": "Mark as Read",
  "Mark as Unread": "Mark as Unread",
  "Password:": "Password:",
  "My Profile": "My Profile",
  "Share": "Share",
  "Document Verified": "Document Verified",
  "Document Not Verified": "Document Not Verified",
  "previous": "previous",
  "Audit Logs": "Audit Logs",
  "Verified": "Verified",
  "next": "next",
  "update": "update",
  "save_view": "Save & View",
  "save_close": "Save & Close",
  "repeat_load": "Save & Repeat",
  "Cancelled": "Cancelled",
  "update_repeat": "Update & Repeat",
  "Log": "Log",
  "Public": "Public",
  "Booked": "Booked",
  "Lock": "Lock",
  "Due Amount": "Due Amount",
  "Loading Point": "Loading Point",
  "Unloading Point": "Unloading Point",
  "Edit Loading Point": "Edit Loading Point",
  "Edit Unloading Point": "Edit Unloading Point",
  "Add Load": "Add Load",
  "Suggestions": "Suggestions",
  "Cancel Load": "Cancel Load",
  "Cancel Offer": "Cancel Offer",
  "save & Add New": "save & Add New",
  "Add offer": "Add offer",
  "Offers": "Offers",
  "Comment": "Comment",
  "Length": "LWH (Ft)",
  "Trucks Board": "Trucks Board",
  "Trucks": "Trucks",
  "Banks": "Banks",
  "Private": "Private",
  "Loads Materials Industries": "Loads Materials Industries",
  "Load Payment Infos": "Load Payment Infos",
  "Update Offer": "Update Offer",
  "active": "active",
  "inactive": "inactive",
  "mark": "mark",
  "as": "as",
  "Transit Documents": "Transit Documents",
  "VIEW": "VIEW",
  "Id": "Id",
  "select": "select",
  "Export": "Export",
  "Accounts": "Accounts",
  "Generate E-way Bill": "Generate E-way Bill",
  "Ton": "Ton",
  "Unit": "Unit",
  "All Transactions": "All Transactions",
  "Transactions": "Transactions",
  "Trucker Receivable": "Trucker Receivable",
  "Trucker Payable": "Trucker Payable",
  "Loader Receivable": "Loader Receivable",
  "Loader Payable": "Loader Payable",
  "Cash Ledger": "Cash Ledger",
  "Bank A/C": "Bank A/C",
  "Bank": "Bank",
  "Sr No.": "Sr No.",
  "User Type": "User Type",
  "Notification Title": "Notification Title",
  "Date & Time": "Date & Time",
  "Clusters VS Routes VS Materials Loads": "Clusters VS Routes VS Materials Loads",
  "Trucker Business Name": "Trucker Business Name",
  "Trucker Trading Name": "Trucker Trading Name",
  "Truck Owner Name": "Truck Owner Name",
  "Truck Owner Mobile No.": "Truck Owner Mobile No.",
  "Truck Driver Name": "Truck Driver Name",
  "Truck Driver Mobile No.": "Truck Driver Mobile No.",
  "Offer Cluster": "Offer Cluster",
  "Loader Business Name": "Loader Business Name",
  "Loader Trading Name": "Loader Trading Name",
  "Loader Primary Name": "Loader Primary Name",
  "Loader Primary Mobile No.": "Loader Primary Mobile No.",
  "Loader Alternative Name": "Loader Alternative Name",
  "Loader Alternative Mobile No.": "Loader Alternative Mobile No.",
  "Load Industry": "Load Industry",
  "Total Truck Freight": "Total Truck Freight",
  "Crossing + Commission": "Crossing + Commission",
  "Reports": "Reports",
  "Clusters vs Loads/Offers": "Clusters vs Loads/Offers",
  "Clusters vs Materials": "Clusters vs Materials",
  "Clusters vs Industry": "Clusters vs Industry",
  "Select Option": "Select Option",
  "Start Date": "Start Date",
  "End Date": "End Date",
  "Routes vs Materials": "Routes vs Materials",
  "Routes vs Industry": "Routes vs Industry",
  "Routes vs Loads/Offers": "Routes vs Loads/Offers",
  "From Date": "From Date",
  "To Date": "To Date",
  "SMS Logs": "SMS Logs",
  "WhatsApp Logs": "WhatsApp Logs",
  "WhatsApp Messages Logs": "WhatsApp Messages Logs",
  "Success": "Success",
  "Failed": "Failed",
  "Generate": "Generate",
  "Charges": "Charges",
  "Payer": "Payer",
  "Invoices": "Invoices",
  "Work With": "Associated With",
  "POD": "POD",
  "Back": "Back",
  "Logs": "Logs",
  "Edit Loader": "Edit Loader",
  "Contact Information": "Contact Information",
  "Reason": "Reason",
  "Document Types": "Document Types",
  "View Trucker": "View Trucker",
  "View Loader": "View Loader",
  "Generate Invoice": "Generate Invoice",
  "bulksmsplans": "bulksmsplans",
  "View log": "View log",
  "Whatsapp Logs": "Whatsapp Logs",
  "Is Active": "Is Active",
  "Delete users": "Delete users",
  "Offer Information": "Offer Information",
  "Driver Information": "Driver Information",
  "Is InActive": "Is InActive",
  "Trucker Ledger Report": "Trucker Ledger Report",
  "Loader Ledger Report": "Loader Ledger Report",
  "Add Offer": "Add Offer",
  "Capacity / No. of Units": "Capacity / No. of Units",
  "Per Ton / Per Unit Price": "Per Ton / Per Unit Price",
  "delete": "delete",
  "Adv.": "Adv.",
  "Total with Freight": "Total with Freight",
  "No Documents": "No Documents",
  "App Name": "Navodaya Transport",
  "Variable": "Variable",
  "SMS Headers": "SMS Headers",
  "Message Templates": "Message Templates",
  "N/A": "N/A",
  "Per Unit Quantity": "Per Unit Quantity",
  "Loading Business Name": "Loading Business Name",
  "Unloading Business Name": "Unloading Business Name",
  "link_expired": "Sorry this link is expired.",
  "Select Date": "Select Date",
  "Custom date range": "Custom date range",
  "confirmation_back": "Back to Confirmation",
  "Crossing": "Crossing",
  "Quantity": "Quantity",
  "Commission": "Commission",
  "Images": "Images",
  "Advance Payment": "Advance Payment",
  "search_mcbook_entries": "MC Book Entries",
  "Individual": "Individual",
  "Is Confidential": "Is Confidential",
  "Account Verification": "Account Verification",
  "Verification Status": "Verification Status",
  "CONFIRM": "CONFIRM",
  "Primary Mobile No": "Primary Mobile No.",
  "Alternative Name": "Alternative Name",
  "Materials/Products": "Materials/Products",
  "Business Area": "Business Area",
  "Alternative Mobile No": "Alternative Mobile No.",
  "Truck Available Area": "Truck Available Area",
  "Pref. Materials/Products": "Pref. Materials/Products",
  "Pref. Routes": "Pref. Routes",
  "Delete Users": "Delete Users",
  "dashboard": {
    "materials": "Materials",
    "routes": "Routes",
    "clusters": "Clusters"
  },
  "Generate Document": "Generate Document",
  "Balance Amount": "Balance Amount",
  "$vuetify": {
    "badge": "Badge",
    "pagination": {
      "ariaLabel": {
        "root": "root",
        "previous": "previous",
        "next": "next",
        "currentPage": "currentPage",
        "page": "page"
      }
    },
    "input": {
      "clear": "clear",
      "appendAction": "appendAction",
      "prependAction": "prependAction",
      "counterSize": "counterSize"
    },
    "fileInput": {
      "counterSize": "counterSize"
    },
    "rating": {
      "ariaLabel": {
        "item": "item"
      }
    }
  },
  "login": {
    "type_login": "Login with password",
    "type_otp": "Login with OTP",
    "mobile": "Email Address or Mobile No.",
    "text": "Please sign-in to your account and start the adventure",
    "platform": "New on our platform?",
    "account": "Create an account",
    "verification": "Two Step Verification",
    "verification_text": "Please check your email address and mobile phone for the verification code.",
    "verification_email": "Please enter the OTP you have received on your registered email address",
    "verification_phone": "Please enter the OTP you have received on your registered mobile no.",
    "security_code": "Type your 6 digit security code",
    "verify": "Verify",
    "get_code": "Didn't get the code?",
    "resend": "Resend",
    "back": "Back to login",
    "account_type": " Account Type",
    "sign_in": "Sign in"
  },
  "register": {
    "register_text": "Adventure starts here",
    "business_name": "Business Name",
    "trading_name": "Trading Name",
    "full_name": "Full Name",
    "app": "Make your app management easy and fun!",
    "sign_up": "Sign up",
    "have_account": "Already have an account",
    "sign_in": "Sign in"
  },
  "master": {
    "document_types": "Document Types",
    "document_type": "Document Type",
    "mc_book": "Mc Book Name",
    "cluster": "Cluster",
    "route": "Route",
    "condition": "Condition",
    "remark": "Remark",
    "material": "Material",
    "industry": "Industry",
    "banner_note": {
      "image_resolution": {
        "title": "Maximum Resolution : ",
        "value": "996 x 560 pixels"
      },
      "file_size": {
        "title": "Maximum File Size : ",
        "value": "5 MB"
      }
    },
    "banner_title": "Banner Title",
    "banners": "banners",
    "remark_description": "Remark Description",
    "sms_header": "SMS Header",
    "sms_headers": "SMS header",
    "template_Id": "Template ID",
    "template": "Template",
    "sub_category": "Sub Category",
    "message_template": "Template Type",
    "template_id": "DLT Template ID",
    "record_selection": "No of Record Selection",
    "flow_id": "Flow Id",
    "sender": "SMS Header(Sender) Id",
    "system_generated": "System Generated"
  },
  "loader": {
    "type": "Loader",
    "load": "Load",
    "add_new_branch": "Add New Branch",
    "create_new_branch": "Create New Branch",
    "add_new_employee": "Add New Employee",
    "create_new_employee": "Create New Employee",
    "search": "Search with Loader Info.",
    "id": "Loader ID",
    "business_name": "Loader Business Name",
    "trading_name": "Loader Trading Name",
    "pri_name": "Loader Primary Name",
    "pri_mobile_no": "Loader Primary Mobile No.",
    "alt_name": "Loader Alternative Name",
    "alt_mobile_no": "Loader Alternative Mobile No.",
    "update_load": "Update Load",
    "edit_load": "Edit Load"
  },
  "trucker": {
    "lwh": "LWH",
    "l": "Length (ft.)",
    "w": "Width (ft.)",
    "h": "Height (ft.)",
    "type": "Trucker",
    "truck": "Truck No.",
    "chassis": "Chassis No.",
    "tyres": "Tyres",
    "capacity": "Capacity",
    "engine": "Engine No.",
    "routes": "Preferred Routes",
    "add_driver": "Add Driver",
    "edit_driver": "Edit Driver",
    "add_truck": "Add Truck",
    "edit_truck": "Edit Truck",
    "add_employee": "Add Employee",
    "edit_employee": "Edit Employee",
    "add_branch": "Add Branch",
    "edit_branch": "Edit Branch",
    "add_bank": "Add Bank",
    "edit_bank": "Edit Bank",
    "add_load": "Add Load",
    "edit_load": "Edit Load",
    "truck_information": "Truck Information",
    "bank_information": "Bank Information",
    "offer_details": "Offer Details",
    "edit_offer": "Edit Offer",
    "add_edit_offers": "Add / Edit Offers",
    "load_details": "Load Details",
    "confirmation_history": "Confirmation History",
    "due_amount": "Due Amount",
    "search": "Search with Trucker Info.",
    "add_new_truck": "Add New Truck",
    "add_new_driver": "Add New Driver",
    "create_new_truck": "Create New Truck",
    "create_new_driver": "Create New Driver",
    "preferred_routes": "No Preferred Routes Found",
    "available_date": "Truck Available Date"
  },
  "confirmations": {
    "confirmation_detail": "Confirmation Details",
    "title": "Confirmations",
    "confirmation_id": "Confirmation ID",
    "confirmation_date": "Confirmation Date",
    "confirmation_details": "Post Confirmation Details",
    "pre_confirmation": "Pre Confirmation Details",
    "post_confirmation": "Post Confirmation",
    "pre_confirmations": "Pre Confirmation",
    "book_now": "Book Now",
    "amount_receive_note": "When set as No, the amount will be paid by Loader directly to the trucker.",
    "send_sms_title": "Confirming this booking will send SMS/WhatsApp notifications to all the stakeholders, including the Loader, Loading/Unloading Points, Trucker, and Driver.",
    "send_sms_action": "proceed with sending it"
  },
  "document": {
    "decline": "decline document",
    "approve": "approve document",
    "delete": "delete document",
    "note": "Note: Password protected documents are not allowed.",
    "upload_files": "Upload Images",
    "export_report": "Export Report Excel",
    "dropzone_info": "Allow .jpg, .png, file types",
    "pdf": "Export Report PDF ",
    "support_type": "Supported Document Types: pdf, jpeg, jpg, png & upto 10 MB",
    "reject": {
      "dialog": {
        "title": "Reason For Rejection",
        "documentName": "Document Name",
        "defaultReason": "Picture Quality is not Good"
      }
    }
  },
  "users": {
    "branch_note": "Note: This tab is for adding your loading/unloading Godowns details",
    "make_payment": "Make Payment",
    "generate_email": "generate an email address",
    "operator": "operators",
    "material": "Material / Product",
    "loading_points": "At least one loading and unloading point is required.",
    "capacity_error": "The sum of loading capacities is not within the selected capacity range. Please ensure the total loading capacities meet the specified limits.",
    "equal_error": "The sum of loading capacities must be equal to the sum of unloading capacities. Please review and ensure the balance between loading and unloading capacities.",
    "all_clusters": "All-clusters",
    "all_products": "All-products",
    "all_industries": "All-industries",
    "trucker": "Trucker",
    "loader": "Loader",
    "delete_image": "delete image",
    "active_user": "active user",
    "verify_user": "verify user",
    "inactive_user": "inactive",
    "unverify_user": "unverify",
    "not_verify_document": "unverify document",
    "verify_document": "verify document",
    "business_name": "Business Name",
    "business_area": "Business Area",
    "business_cluster": "Business Cluster",
    "trading_name": "Trading Name",
    "gst_no": "GST No.",
    "pan_no": "PAN No.",
    "address": "Address",
    "area": "Area",
    "website": "Website",
    "notes": "Notes",
    "designation": "Designation",
    "aadhar_no": "Aadhar No.",
    "email_address": "Email Address",
    "mobile_no": "Mobile No. (Primary)",
    "landline_pri": "Landline No. (Primary)",
    "landline_no": "Landline No.",
    "valid_from": "Valid From",
    "valid_to": "Valid To",
    "license_no": "Driver License No.",
    "charges": "Charges",
    "cnf_id": "CNF ID:",
    "full_address": "Full Address",
    "landline2": "Landline No. 2",
    "landline3": "Landline No. 3",
    "mobile2": "Mobile No. 2",
    "mobile3": "Mobile No. 3",
    "transactions": "Transactions",
    "view": {
      "trucker_profile": "Trucker Profile",
      "loader_profile": "Loader Profile",
      "audit_logs": "Audit Logs",
      "edit_profile": "Edit Profile",
      "generate_send_password": "Generate & Send Password"
    },
    "search": {
      "id": "ID",
      "loader_id": "Loader ID",
      "business_name": "Business Name",
      "business_area": "Business Area",
      "business_cluster": "Business Cluster",
      "name": "Name",
      "mobile_no": "Mobile No.",
      "locked": "Locked",
      "unlocked": "Unlock",
      "trading_name": "Trading Name",
      "lockers_full_name": "Locker Full Name",
      "industries": "Industries",
      "status": "Status",
      "verification": "Verification",
      "doc_status": "Doc Status",
      "landline_no": "Landline No.",
      "email": "Email Address",
      "offer_id": "Offer ID",
      "owner_name": "Owner Name",
      "owner_mobile_no": "Owner Mobile No.",
      "driver_name": "Driver Name",
      "driver_mobile_no": "Driver Mobile No.",
      "truck_no": "Truck No.",
      "chassis_no": "Chassis No.",
      "designation": "Designation",
      "bank_name": "Bank Name",
      "bank_no": "Bank Phone No.",
      "ifsc": "Bank IFSC Code",
      "branch_address": "Bank Branch Address",
      "available_area": "Available Area",
      "account_type": "Bank Account Type",
      "available_date": "Available Date",
      "preferred_materials": "Pref. Materials / Products",
      "preferred_routes": "Preferred Routes",
      "remark": "Remark",
      "pan": "PAN No.",
      "isVerified": "Is Verified",
      "tyres": "Tyres",
      "capacity": "Capacity",
      "visibility": "Visibility",
      "engine_no": "Engine No.",
      "confirmation_id": "Confirmation ID",
      "description": "Description",
      "payment_type": "Payment Type",
      "reference": "Reference",
      "products": "Pref. Materials / Products",
      "industry": "Industry",
      "materials": "Pref. Materials / Products",
      "conditions": "Conditions",
      "charges": "Charges",
      "load_id": "Load ID",
      "pri_name": "Primary Name",
      "pri_mobile_no": "Primary Mobile No.",
      "loading_cluster": "Loading Cluster",
      "route": "Targeted Route",
      "alt_name": "Alternate Name",
      "alt_mobile_no": "Alternate Mobile No.",
      "loading_points": "Loading Points",
      "unloading_points": "Unloading Points",
      "loading_point": "Loading Point",
      "unloading_point": "Unloading Point",
      "loading_charges": "Loading Charges",
      "unloading_charges": "Unloading Charges",
      "loading_mobile_no": "Loading Mobile No.",
      "unloading_mobile_no": "Unloading Mobile No.",
      "freight": "Freight Method",
      "trucker_id": "Trucker ID",
      "loading_date": "Loading Date",
      "offer_cluster": "Offer Cluster",
      "preferred_route": "Preferred Routes",
      "of": "of",
      "notification_title": "Notification Title",
      "user_type": "User Type",
      "utr_no": "UTR Number",
      "amount": "Amount",
      "lead_distance": "Lead Distance",
      "doc_verification": "Doc Verification",
      "branch_name": "Name",
      "transaction_id": "Transaction ID",
    }
  },
  "bank": {
    "saving_account": "Savings account",
    "current_account": "Current account",
    "salary_account": "Salary account",
    "fixed_account": "Fixed deposit account",
    "recurring_account": "Recurring deposit account",
    "nri_account": "NRI account",
    "account_no": "Bank Account No.:",
    "name": "Bank Name:",
    "code": "Bank IFSC Code:",
    "type": "Bank Account Type:",
    "branch": "Bank Branch Address:",
    "pan_no": "PAN No.:",
    "account_name": "Bank Account Name:",
    "is_primary": "Is Primary:"
  },
  "general": {
    "campaign": "Campaign",
    "navodaya_name": "NAVODAYA TRANSPORT",
    "preview": "PREVIEW",
    "verified": "Verified",
    "unverified": "Unverified",
    "verify": "Verify",
    "unverify": "Unverify",
    "inactive": "Inactive",
    "all": "All",
    "active": "Active",
    "in_active": "InActive",
    "delete": "Delete",
    "invoice_delete": "delete selected invoice",
    "confirmation_Record": "No confirmation history found.",
    "select_options": "Select Options",
    "select_all": "Select all",
    "submit": "submit",
    "search": "Search",
    "no_data_found": "No Data Found",
    "yes": "YES",
    "no": "No",
    "available": "Available",
    "in_process": "In process",
    "public": "Public",
    "private": "Private",
    "cancel": "Cancel",
    "confirmation_Record": "No confirmations found",
    "bank_Details": "No bank details found.",
    "save_repeat": "By doing so, the existing record will be saved, and the duplicate record will be repeated on this page.",
    "confirm_msg": "Save & Repeat the ",
    "today": "Today",
    "yesterday": "Yesterday",
    "yesterday_date": "Yesterday to date",
    "week": "Week",
    "last_week": "Last week",
    "last_date": "Last week to date",
    "month": "Month",
    "last_month": "Last month",
    "last_month_date": "Last month to date",
    "year": "Year",
    "last_year": "Last year",
    "last_year_date": "Last year to date",
    "date": "Date",
    "range": "Range",
    "send_pod_reminder": "Send POD Reminder SMS",
    "btn": {
      "submit": "Submit",
      "cancel": "Cancel",
      "reset": "Reset",
      "search": "Search",
      "share": "Share",
      "delete": "Delete",
      "clear_all": "clear All",
      "daily_report": "Daily EOD Report",
      "edit_loader": "Edit Loader Info.",
      "edit_trucker": "Edit Trucker Info.",
      "audit_log": "Audit Log",
      "copy": "Copy Text"
    },
    "btnType": {
      "submit": "submit",
      "cancel": "cancel",
      "cancellation": "Proceed with cancellation"
    }
  },
  "user_profile": {
    "update_user": "Update User",
    "full_name": "Full Name",
    "email": "Email",
    "role": "Role",
    "contact": "Contact",
    "landline_no": "Landline No.",
    "details": "Details",
    "edit_profile": "Edit Profile",
    "generate_password": "generate password"
  },
  "change_password": {
    "title": "Change Password",
    "current_password": "Current Password",
    "new_password": "New Password",
    "confirm_new_password": "Confirm New Password",
    "password_requirements": "Password Requirements",
    "save_changes": "Save changes"
  },
  "forgot_password": {
    "title": "Forgot Password",
    "subtitle": "Enter your email, and we'll send you instructions to reset your password. Check your inbox for further details.",
    "email": "Email"
  },
  "reset_password": {
    "title": "Reset Password",
    "password": "Password",
    "confirm_password": "Confirm Password",
    "set_new_password": "Set New Password",
    "back_to_login": "Back to login"
  },
  "validation": {
    "email": "Please enter valid email address.",
    "website": "Please enter valid website URL.",
    "password": "The field must contain at least one uppercase letter, one lowercase letter, one special character, one digit, and be a minimum of 8 characters in length.",
    "confirm_password": "The Confirm Password field does not match the original password. Please ensure both entries are identical.",
    "current_password": "New password cannot be the same as the current password. Please choose a different password for the security.",
    "number": "Please enter a valid numeric value.",
    "truck_no": "Truck number should not be greater than 15 characters.",
    "url_error": "The Download URL is not available",
    "doc_upload": "File size exceeds 10 MB. Please select a smaller file.",
    "banner_upload": "File size exceeds 5 MB. Please select a smaller file.",
    "freight_amount": "The amount should be less then or equal to Freight total amount",
    "receiving_amount": "The receiving amount should be less than or equal to the balance amount",
    "pod_receiving_amount": "The Pod Receiving Amount should be less than Total Pod Amount",
    "copied": "Text copied to clipboard!",
    "bank_details_copied": "Bank details copied to clipboard!",
    "failed_copy": "Failed to copy text. Please try again.",
    "phone_no_text": "Please enter valid 10-digit numbers separated by commas.",
    "greater_zero": "Please enter amount greater than zero."
  },
  "confirmation": {
    "pod_records": "POD Records",
    "balance_freight": "Balance Freight",
    "balance_amt": "Balance Amount",
    "quick_confirmation": "Quick Confirmation",
    "search_loader_placeholder": "Search loader id, business name, trading name, primary mobile no.",
    "search_trucker_placeholder": "Search trucker id, business name, trading name, primary mobile no.",
    "add_transaction_details": "Add Transaction Details",
    "copy_payment_link": "Copy Payment Link",
    "pr_or_commission": "PR + OR + Commission",
    "crossing_commission": "Crossing (PR + OR) + Commission",
    "trucker_payment_info": "Trucker Payment Information (Bank Account)",
    "loader_payment_info": "Loader Payment Information (Bank Account / Online Transactions)",
    "receiving_to_navodaya": "Receiving to Navodaya?",
    "receiving_amount": "Receiving Amount",
    "copy_bank_details": "Copy Bank Account details",
    "trucker_bank_details": "Trucker Bank Account details",
    "loader_bank_details": "Loader Bank Account details",
    "admin_bank_details": "Navodaya Bank Account Details",
    "standing_instructions": "Standing Instructions",
    "freight_payment_structure": "Freight Method & Payment Structure",
    "make_payment": "Make Payment",
    "bank_account_or_online": "Bank Account / Online Transactions",
    "credit_debit": "Credit / Debit Cards",
    "total_payment": "Total Payment",
    "to_pay_navodaya": "To Pay Navodaya",
    "payment_structure": "Payment Structure",
    "freight_method": "Freight Method",
    "payment_to_pay": "Payment to Pay",
    "loader_payment": "Loader Payment Link",
    "trucker_payment": "Trucker Payment Link",
    "create": "create confirmation"
  },
  "quick_confirmation": {
    "list": "Quick Confirmations List",
    "delete": "delete this quick confirmation"
  },
  "success_message": {
    "otp_login": "The user has been verified successfully.",
    "otp_mobile": "Your mobile no. is verified successfully.",
    "otp_email": "Your email address is verified successfully."
  },
  "accounts": {
    "search": {
      "bank_account": "Bank Account",
      "mc_Book_id": "Mc Book ID",
      "user_id": "User ID",
      "title": "Centralized Transaction",
      "id": "Confirmation ID",
      "payment_type": "Payment Type",
      "from_date": "From Date",
      "to_date": "To Date",
      "export": "Export",
      "select": "Select",
      "freight": "Freight",
      "pod": "POD",
      "pr": "PR",
      "company_name": "Company Name",
      "tons": "tons",
      "receivable": "Receivable",
      "received": "Received",
      "not_received": "Not Received",
      "paid": "Paid",
      "balance": "Balance",
      "excel": "Excel",
      "pdf": "PDF",
      "mc_book": "MC Book",
      "yashwantrao": "YashwantRao",
      "mc_loader_id": "Loader ID",
      "mcloader_business_name": "Loader Business Name",
      "mc_trucker_id": "Trucker ID",
      "mc_trucker_name": "Trucker Business Name",
      "mc_loader_business_name": "Loader Business Name",
      "transactions": "Transactions",
      "cash_ledger": "Cash Ledger",
      "business_name": "Business Name",
      "trading_name": "Trading Name"
    },
    "bank_account": "Bank Account",
    "confirmation_id": "Confirmation ID",
    "trucker": {
      "file_send": "File Send",
      "ledger_report": "Trucker Ledger Report",
      "transaction_report": "All Transaction Report"
    },
    "loader": {
      "ledger_report": "Loader Ledger Report"
    }
  },
  "reports": {
    "Clusters VS Routes Report": "Clusters VS Routes Report",
    "template_id": "Template ID",
    "template_type": "Template Type",
    "template_name": "Template Name",
    "template": "Template",
    "sms_status": "SMS Status",
    "total_counts": "Total Counts",
    "messages": "Messages",
    "sms_sent": "SMS Sent Report",
    "select_routes": "Please select routes from search box to see the report"
  },
  "errors": {
    "desc": " No Description Found",
    "primary_required": "Please enter primary name.",
    "Routes VS Loads/Offers Report": "Routes VS Loads/Offers Report",
    "template_id": "Template ID",
    "template": "Template",
    "sms_status": "SMS Status",
    "messages": "Messages",
    "generate_email_error": "Please enter valid mobile no."
  },
  "generate_email": {
    "mc_book": "generate an email address for this MC Book"
  },
  "delete_users": {
    "title": "Delete Users Requests",
    "delete_users_confirm": "Are you sure you want to delete these users?",
    "search": {
      "id": "ID",
      "email": "Email",
      "mobile": "Mobile",
      "role": "User Role",
      "delete_type": "Delete Type",
      "status": "Status"
    },
    "status": {
      "partial": "Partial Delete",
      "full": "Full Delete"
    }
  }
}
