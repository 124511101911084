import axios from '@/plugins/axios'

export const accountService = () => {
  const getAccountList = (module: string, accountsOptions: object) => {
    return axios.get(`/v1/accounts/${module}`, { params: accountsOptions })
  }

  const exportAccounts = (module: string, exportFile: string, data: object) => {
    return axios.get(`/v1/accounts/${module}/export?reportType=${exportFile}`, {
      params: data,
      responseType: 'blob',
    })
  }

  const getTransactionsStats = (accountsOptions: object) => {
    return axios.get('/v1/accounts/statistics/transactions', { params: accountsOptions })
  }

  return {
    getAccountList, exportAccounts, getTransactionsStats,
  }
}
