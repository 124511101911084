import { addTruckCapacity, confirmationListKeys, confirmationStatus, statusChipColor } from '@/constants'
import { downloadAndViewFile } from '@/helpers'
import { useReportsStore } from '@/stores/reports'
import { useToasterStore } from '@/stores/toaster'

const { state: toasterStoreState } = useToasterStore()
const { state: reportStoreState, actions: reportStoreActions } = useReportsStore()

export const getConfirmationTruckCapacity = (capacity: string) => addTruckCapacity.filter(capacityField => capacityField?.value === capacity?.toString()).find(Boolean)?.text

export const extractExtensionFromFileName = (name: string) => name?.split('.')?.find(Boolean)

export const getConfirmationStatus = (status: string, isCompleted: number) => {
  let statusValue
  if (status === confirmationListKeys.booked)
    statusValue = isCompleted ? confirmationStatus.Completed : confirmationStatus.Confirmed
  else
    statusValue = confirmationStatus?.[status]

  return {
    text: statusValue,
    color: statusChipColor[statusValue],
  }
}

export const exportReport = async (
  fileType: 'excel' | 'pdf',
  moduleType: string,
  accountsSearchFields: Record<string, any>,
  fileName: string,

) => {
  reportStoreState.hasLoader = true

  const filteredFields = Object.keys(accountsSearchFields)
    .filter(key => accountsSearchFields[key] !== '')
    .reduce((obj, key) => {
      obj[key] = accountsSearchFields[key]

      return obj
    }, {} as Record<string, any>)

  try {
    const buffer: any = await reportStoreActions.exportReportsFiles(moduleType, fileType, filteredFields)

    downloadAndViewFile(buffer, fileType, fileName)
  }
  catch (error) {
    console.error('Error exporting file:', error)
    toasterStoreState.displaySnackbar('Failed to export the file. Please try again later.', 'error')
  }
  finally {
    reportStoreState.hasLoader = false
  }
}

export const exportMessageReport = async (
  fileType: 'excel' | 'pdf',
  moduleType: string,
  campaignId: string,
  accountsSearchFields: Record<string, any>,

) => {
  reportStoreState.loading = true

  const filteredFields = Object.keys(accountsSearchFields)
    .filter(key => accountsSearchFields[key] !== '')
    .reduce((obj, key) => {
      obj[key] = accountsSearchFields[key]

      return obj
    }, {} as Record<string, any>)

  try {
    const buffer: any = await reportStoreActions.exportMessageFiles(moduleType, fileType, campaignId, filteredFields)

    downloadAndViewFile(buffer, fileType, 'messages')
  }
  catch (error) {
    console.error('Error exporting file:', error)
    toasterStoreState.displaySnackbar('Failed to export the file. Please try again later.', 'error')
  }
  finally {
    reportStoreState.loading = false
  }
}

export const getInvoiceType = (val: string) => {
  if (val === 'CONSIGNMENT')
    return 'Consignment Note'
  else
    return 'Loading Slip'
}

export const exportCampaignsReport = async (moduleType: string, SearchFields: Record<string, any>, fileName: string) => {
  reportStoreState.loading = true

  const filteredFields = Object.keys(SearchFields)
    .filter(key => SearchFields[key] !== '')
    .reduce((obj, key) => {
      obj[key] = SearchFields[key]

      return obj
    }, {} as Record<string, any>)

  try {
    const buffer: any = await reportStoreActions.exportCampaignsFiles(moduleType, filteredFields)

    downloadAndViewFile(buffer, 'excel', fileName)
  }
  catch (error) {
    console.error('Error exporting file:', error)
    toasterStoreState.displaySnackbar('Failed to export the file. Please try again later.', 'error')
  }
  finally {
    reportStoreState.loading = false
  }
}
