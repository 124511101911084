import { reactive } from 'vue'
import { accountService } from '@/services/apis'

const { getAccountList, exportAccounts, getTransactionsStats } = accountService()

const accountStoreState = reactive({
  isSnackbarVisible: false,
  accountList: [],
  isLoading: false,
  totalCount: 0,
  hasLoader: false,
  statistics: {},
})

const accountStoreActions = {
  async getAccountStore(module: string, accountsOptions: object) {
    accountStoreState.isLoading = true

    const response = await getAccountList(module, accountsOptions)

    if (response?.data) {
      accountStoreState.accountList = response.data.data.data
      accountStoreState.totalCount = response.data.data.totalCount
    }

    accountStoreState.isLoading = false
  },

  async exportFiles(module: string, exportFile: string, data: object) {
    try {
      const response = await exportAccounts(module, exportFile, data)
      if (response?.data) {
        accountStoreState.hasLoader = false

        return response.data
      }
    }
    catch (error) {
      return false
    }
  },

  async getAccountStatistics(accountsOptions: object) {
    accountStoreState.isLoading = true

    const response = await getTransactionsStats(accountsOptions)

    if (response?.data)
      accountStoreState.statistics = response.data.data

    accountStoreState.isLoading = false
  },
}

export const useAccountStore = () => ({
  state: accountStoreState,
  actions: accountStoreActions,
})
