import axios from '@/plugins/axios'

export const authenticationService = () => {
  const signUp = (data: object) => {
    return axios.post('/v1/signup', data)
  }

  const otpLogin = (data: object) => {
    return axios.post('/v1/otp-login', data)
  }

  const sendOtp = (data: object) => {
    return axios.post('/v2/notifications/send-otp', data)
  }

  const getUser = (data: object) => {
    return axios.get('/v1/user', data)
  }

  const forgotPassword = (data: object) => {
    return axios.post('/v1/password/email', data)
  }

  const resetPassword = (data: object) => {
    return axios.post('/v1/password/reset', data)
  }

  const generatePassword = (uuid: string, data: object) => {
    return axios.post(`/v1/users/${uuid}/generate-password`, data)
  }

  const refreshTokenApi = () => {
    return axios.get('/v1/auth/refresh-token')
  }

  const verifyOtp = (data: object) => {
    return axios.post('/v2/notifications/otp-verification', data)
  }

  const verifySignUpOtp = (data: object) => {
    return axios.post('/v2/notifications/signup-otp-verification', data)
  }

  const sendOtpForLogin = (data: object) => {
    return axios.post('/v2/notifications/send-otp/login', data)
  }

  return { getUser, forgotPassword, resetPassword, signUp, otpLogin, sendOtp, generatePassword, refreshTokenApi, verifyOtp, verifySignUpOtp, sendOtpForLogin }
}
