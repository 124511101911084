import './apps/user-list'
import './jwt'
import mock from './mock'
import './pages/datatable'

import './pages/profile'

// Apps
import './apps/invoice'

// Dashboard
import './dashboard/analytics'

// forwards the matched request over network
mock.onAny().passThrough()
