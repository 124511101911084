// Get the API key from the environment variable
const apiKey = import.meta.env.VITE_GOOGLE_PLACE_NAME_API

// Check if the API key is defined
if (apiKey) {
  const script = document.createElement('script')

  script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places&callback=initMap`

  window.initMap = function () {
    // JS API is loaded and available
  }

  script.onload = () => {
    // The Google Maps API is now available for use
    console.log('Google Maps API loaded.')

    // You can initialize your maps or perform other tasks here
  }

  script.onerror = () => {
    console.error('Failed to load Google Maps API.')
  }

  document.body.appendChild(script)
}
else {
  console.error('Google Maps API key not found.')
}
