import { reactive } from 'vue'
import { reportService } from '@/services/apis'

const { getReportList, exportReports, getCampaigns, getLogs, exportMessageReports, exportCampaignsReports } = reportService()

const reportStoreState = reactive({
  isSnackbarVisible: false,
  reportList: [],
  isLoading: false,
  totalCount: 0,
  hasLoader: false,
  isLoader: false,
  getCampaigns: [],
  getLogsList: [],
  getWaCampaignDetails: [],
  getWaLogsList: [],
  totalCounts: 0,
  loading: false,
  getCampaignDetails: [],
  clusters: [],
})

const reportStoreActions = {
  async getReportStore(reportsOptions: object) {
    reportStoreState.isLoading = true

    const response = await getReportList(reportsOptions)

    if (response?.data) {
      reportStoreState.reportList = response.data.data.results
      reportStoreState.totalCount = response.data.data.totalCount
      reportStoreState.clusters = response.data.data.clusters
    }

    reportStoreState.isLoading = false
  },
  async exportReportsFiles(module: string, exportFile: string, data: object) {
    try {
      const response = await exportReports(module, exportFile, data)
      if (response?.data) {
        reportStoreState.hasLoader = false

        return response.data
      }
    }
    catch (error) {
      return false
    }
  },

  async getSmsCampaignsDetails(logsOf: string, reportsOptions: object) {
    try {
      reportStoreState.isLoading = true

      const response = await getCampaigns(logsOf, reportsOptions)

      reportStoreState.getCampaigns = response?.data?.data?.campaigns
      reportStoreState.totalCount = response?.data?.data?.totalCount
      reportStoreState.isLoading = false
    }

    catch (error) {
      reportStoreState.isLoading = false
    }
  },

  async getLogsDetails(logsOf: string, campaignId: string, reportsOptions: object) {
    try {
      reportStoreState.isLoader = true

      const response = await getLogs(logsOf, campaignId, reportsOptions)

      const {
        data: {
          data: { campaignDetails, smsStatusLogs, totalCount },
        },
      } = response

      reportStoreState.getCampaignDetails = campaignDetails
      reportStoreState.getLogsList = smsStatusLogs
      reportStoreState.totalCount = totalCount
      reportStoreState.isLoader = false
    }

    catch (error) {
      reportStoreState.isLoader = false
    }
  },

  async getWaLogsDetails(logsOf: string, campaignId: string, reportsOptions: object) {
    try {
      reportStoreState.isLoading = true

      const response = await getLogs(logsOf, campaignId, reportsOptions)

      const {
        data: {
          data: { campaignDetails, whatsappLogs, totalCount },
        },
      } = response

      reportStoreState.getWaCampaignDetails = campaignDetails
      reportStoreState.getWaLogsList = whatsappLogs
      reportStoreState.totalCounts = totalCount
      reportStoreState.isLoading = false
    }

    catch (error) {
      reportStoreState.isLoading = false
    }
  },

  async exportMessageFiles(module: string, exportFile: string, campaignId: string, data: object) {
    try {
      const response = await exportMessageReports(module, exportFile, campaignId, data)
      if (response?.data) {
        reportStoreState.loading = false

        return response.data
      }
    }
    catch (error) {
      return false
    }
  },

  async exportCampaignsFiles(module: string, data: object) {
    try {
      const response = await exportCampaignsReports(module, data)
      if (response?.data) {
        reportStoreState.loading = false

        return response.data
      }
    }
    catch (error) {
      return false
    }
  },
}

export const useReportsStore = () => ({
  state: reportStoreState,
  actions: reportStoreActions,
})
