const errorFieldsMap = {
  'phone': 'phoneError',
  'email': 'emailError',
  'name': 'nameError',
  'place_id': 'placeError',
  'display_name': 'displayNameError',
  'code': 'codeError',
  'mobile': 'mobileError',
  'offer.truck_number': 'truckNoError',
  'offer.driver.preferred_contact': 'driverNoError',
  'file': 'bannerImage',
  'truck_number': 'truckNoError',
  'loading_date': 'loadingDateError',
}

export const setFieldError = (errorStoreState: any, firstErrorField: string, errorMessage: string) => {
  const fieldProperty = errorFieldsMap[firstErrorField]

  if (fieldProperty)
    errorStoreState[fieldProperty] = errorMessage
}
