import i18n from "@/plugins/i18n"

export const usersLoaderColumns = [
  {
    column_name: 'Loader ID',
    column_fun: 'id',
    column_value: '',
  },
  {
    column_name: 'Business Name',
    column_fun: 'businessName',
    column_value: '',
    is_bold: true,
  },
  {
    column_name: 'Business Area',
    column_fun: '',
    column_value: '',
    is_bold: true,
  },
  {
    column_name: 'Name',
    column_fun: '',
    column_value: 'fullname',
  },
  {
    column_name: 'Trading Name',
    column_fun: 'tradingName',
    column_value: '',
    is_bold: true,
  },
  {
    column_name: 'Business Cluster',
    column_fun: 'cluster',
    column_value: '',
    is_bold: true,
  },
  {
    column_name: 'Mobile No.',
    column_fun: '',
    column_value: 'phone',
  },
  {
    column_name: 'Industry',
    column_fun: 'preferred_industries',
    column_value: '',
  },
  {
    column_name: 'Status',
    column_fun: 'status',
    column_value: '',
  },
  {
    column_name: 'Landline No.',
    column_fun: 'landLineNo',
    column_value: '',
  },
  {
    column_name: 'Materials / Products',
    column_fun: 'materialsData',
    column_value: '',
  },
  {
    column_name: 'Verification',
    column_fun: 'verified',
    column_value: '',
  },
  {
    column_name: 'Created Timestamp',
    column_fun: 'createdAt',
    column_value: '',
  },
  {
    column_name: 'Updated Timestamp',
    column_fun: 'updatedAt',
    column_value: '',
  },
  {
    column_name: 'Doc Verification',
    column_fun: 'docsVerified',
    column_value: '',
  },
  {
    column_name: 'Max. Unloading Route',
    column_fun: 'max_unloading_route',
    column_value: '',
  },
  {
    column_name: 'Max. Loading Cluster',
    column_fun: 'max_loading_cluster',
    column_value: '',
  },
  {
    column_name: '',
    column_fun: '',
    column_value: '',
  },
]

export const usersTruckerColumns = [
  {
    column_name: 'Trucker ID',
    column_fun: 'id',
    column_value: '',
  },
  {
    column_name: 'Business Name',
    column_fun: 'businessName',
    column_value: '',
    is_bold: true,
  },
  {
    column_name: 'Business Area',
    column_fun: '',
    column_value: '',
    is_bold: true,
  },
  {
    column_name: 'Name',
    column_fun: '',
    column_value: 'fullname',
  },
  {
    column_name: 'Trading Name',
    column_fun: 'tradingName',
    column_value: '',
    is_bold: true,
  },
  {
    column_name: 'Business Cluster',
    column_fun: 'cluster',
    column_value: '',
    is_bold: true,
  },
  {
    column_name: 'Mobile No.',
    column_fun: '',
    column_value: 'phone',
  },
  {
    column_name: 'Max. Unloading Route',
    column_fun: 'max_unloading_route',
    column_value: '',
  },
  {
    column_name: 'Status',
    column_fun: 'status',
    column_value: '',
  },
  {
    column_name: 'Landline No.',
    column_fun: 'landLineNo',
    column_value: '',
  },
  {
    column_name: 'Max. Loading Cluster',
    column_fun: 'max_loading_cluster',
    column_value: '',
  },
  {
    column_name: 'Verification',
    column_fun: 'verified',
    column_value: '',
  },
  {
    column_name: 'Created Timestamp',
    column_fun: 'createdAt',
    column_value: '',
  },
  {
    column_name: 'Updated Timestamp',
    column_fun: 'updatedAt',
    column_value: '',
  },
  {
    column_name: 'Doc Verification',
    column_fun: 'docsVerified',
    column_value: '',
  },
]

export const adminOffersColumns = [
  {
    column_name: 'Offer ID',
    column_value: 'id',
  },
  {
    column_name: 'Business Area',
    column_value: '',
    is_bold: true,
  },
  {
    column_name: 'Business Name',
    column_value: '',
  },
  {
    column_name: 'Trucker ID',
    column_value: 'trucker_id',
  },
  {
    column_name: 'Offer Cluster',
    column_value: '',
    is_bold: true,
  },
  {
    column_name: 'Trading Name',
    column_value: 'trading_name',
  },
  {
    column_name: 'Owner Name',
    column_value: 'primary_fullname',
  },
  {
    column_name: 'Driver Name',
    column_value: 'driver_fullname',
  },
  {
    column_name: 'Truck No.',
    column_value: 'truck_number',
    is_bold: true,
  },
  {
    column_name: 'Owner Mobile No.',
    column_value: '',
  },
  {
    column_name: 'Driver Mobile No.',
    column_value: '',
  },
  {
    column_name: 'Tyres',
    column_value: 'tyres',
    is_bold: true,
  },
  {
    column_name: 'Alt Name',
    column_value: 'alternative_fullname',
  },
  {
    column_name: 'Preferred Routes',
    column_value: '',
  },
  {
    column_name: 'Available Area',
    column_value: '',
    is_bold: true,
  },
  {
    column_name: 'Alt Mobile No.',
    column_value: '',
  },
  {
    column_name: 'Pref. Materials / Products',
    column_value: '',
  },
  {
    column_name: 'Available Date',
    column_value: 'loading_date',
    is_bold: true,
  },
  {
    column_name: 'Created Timestamp',
    column_value: 'created_at',
  },
  {
    column_name: 'Conditions',
    column_value: '',
  },
  {
    column_name: 'Status',
    column_value: 'status',
  },
  {
    column_name: 'Updated Timestamp',
    column_value: 'updated_at',
  },
  {
    column_name: 'Visibility',
    column_value: 'is_public',
  },
  {
    column_name: 'Remarks',
    column_value: 'remark',
    condition: (column_name: string, column_value: string) => column_name === 'Status' && column_value === 'Cancelled',
  },
]

export const usersOffersColumns = [
  {
    column_name: 'Offer ID',
    column_value: 'id',
  },
  {
    column_name: 'Business Area',
    column_value: '',
    is_bold: true,
  },
  {
    column_name: 'Business Name',
    column_value: '',
  },
  {
    column_name: 'Trucker ID',
    column_value: 'trucker_id',
  },
  {
    column_name: 'Offer Cluster',
    column_value: '',
    is_bold: true,
  },
  {
    column_name: 'Trading Name',
    column_value: 'trading_name',
  },
  {
    column_name: 'Owner Name',
    column_value: 'primary_fullname',
  },
  {
    column_name: 'Driver Name',
    column_value: 'driver_fullname',
  },
  {
    column_name: 'Truck No.',
    column_value: 'truck_number',
    is_bold: true,
  },
  {
    column_name: 'Owner Mobile No.',
    column_value: '',
  },
  {
    column_name: 'Driver Mobile No.',
    column_value: '',
  },
  {
    column_name: 'Tyres',
    column_value: 'tyres',
    is_bold: true,
  },
  {
    column_name: 'Alt Name',
    column_value: 'alternative_fullname',
  },
  {
    column_name: 'Preferred Routes',
    column_value: '',
  },
  {
    column_name: 'Available Area',
    column_value: '',
    is_bold: true,
  },
  {
    column_name: 'Alt Mobile No.',
    column_value: '',
  },
  {
    column_name: 'Pref. Materials / Products',
    column_value: '',
  },
  {
    column_name: 'Available Date',
    column_value: 'loading_date',
    is_bold: true,
  },
  {
    column_name: 'Created Timestamp',
    column_value: 'created_at',
  },
  {
    column_name: 'Conditions',
    column_value: '',
  },
  {
    column_name: 'Status',
    column_value: 'status',
  },
  {
    column_name: 'Updated Timestamp',
    column_value: 'updated_at',
  },
  {
    column_name: 'Remarks',
    column_value: 'remark',
    condition: (column_name: string, column_value: string) => column_name === 'Status' && column_value === 'Cancelled',
  },
  {
    column_name: '',
    column_value: '',
  },
]

export const adminOffersSuggestionColumns = [
  {
    column_name: 'Offer ID',
    column_value: 'id',
  },
  {
    column_name: 'Business Area',
    column_value: '',
    is_bold: true,
  },
  {
    column_name: 'Business Name',
    column_value: '',
  },
  {
    column_name: 'Trucker ID',
    column_value: 'trucker_id',
  },
  {
    column_name: 'Offer Cluster',
    column_value: '',
    is_bold: true,
  },
  {
    column_name: 'Trading Name',
    column_value: 'trading_name',
  },
  {
    column_name: 'Owner Name',
    column_value: 'primary_fullname',
  },
  {
    column_name: 'Driver Name',
    column_value: 'driver_fullname',
  },
  {
    column_name: 'Truck No.',
    column_value: 'truck_number',
    is_bold: true,
  },
  {
    column_name: 'Owner Mobile No.',
    column_value: '',
  },
  {
    column_name: 'Driver Mobile No.',
    column_value: '',
  },
  {
    column_name: 'Tyres',
    column_value: 'tyres',
    is_bold: true,
  },
  {
    column_name: 'Alt Name',
    column_value: 'alternative_fullname',
  },
  {
    column_name: 'Preferred Routes',
    column_value: '',
  },
  {
    column_name: 'Available Area',
    column_value: '',
    is_bold: true,
  },
  {
    column_name: 'Alt Mobile No.',
    column_value: '',
  },
  {
    column_name: 'Pref. Materials / Products',
    column_value: '',
  },
  {
    column_name: 'Available Date',
    column_value: 'loading_date',
    is_bold: true,
  },
  {
    column_name: 'Created Timestamp',
    column_value: 'created_at',
  },
  {
    column_name: 'Conditions',
    column_value: '',
  },
  {
    column_name: 'Status',
    column_value: 'status',
  },
  {
    column_name: 'Updated Timestamp',
    column_value: 'updated_at',
  },
  {
    column_name: 'Remarks',
    column_value: 'remark',
  },
  {
    column_name: 'Visibility',
    column_value: 'is_public',
  },
  {
    column_name: 'Lead Distance',
    column_value: 'lead_distance',
  },
  {
    column_name: '',
    column_value: '',
  },
  {
    column_name: '',
    column_value: '',
  },
]

export const usersOffersSuggestionColumns = [
  {
    column_name: 'Offer ID',
    column_value: 'id',
  },
  {
    column_name: 'Truck No.',
    column_value: 'truck_number',
    is_bold: true,
  },
  {
    column_name: 'Offer Cluster',
    column_value: '',
    is_bold: true,
  },
  {
    column_name: 'Preferred Routes',
    column_value: '',
  },
  {
    column_name: 'Tyres',
    column_value: 'tyres',
    is_bold: true,
  },
  {
    column_name: 'Available Date',
    column_value: 'loading_date',
    is_bold: true,
  },
  {
    column_name: 'Pref. Materials / Products',
    column_value: '',
  },
  {
    column_name: 'Capacity',
    column_value: 'capacity',
  },
  {
    column_name: 'Conditions',
    column_value: '',
  },
  {
    column_name: 'Created Timestamp',
    column_value: 'created_at',
  },
  {
    column_name: 'Updated Timestamp',
    column_value: 'updated_at',
  },
  {
    column_name: 'Status',
    column_value: 'status',
  },
  {
    column_name: 'Lead Distance',
    column_value: 'lead_distance',
  },
  {
    column_name: '',
    column_value: '',
  },
  {
    column_name: '',
    column_value: '',
  },
]

export const loadersLoadSuggestionColumns = [
  {
    column_name: 'Offer ID',
    column_value: 'id',
  },
  {
    column_name: 'Truck No',
    column_value: 'truck_number',
    is_bold: true,
  },
  {
    column_name: 'Offer Cluster',
    column_value: 'clusters_place_name',
    is_bold: true,
  },
  {
    column_name: 'Preferred Routes',
    column_value: '',
  },
  {
    column_name: 'Tyres',
    column_value: 'tyres',
    is_bold: true,
  },
  {
    column_name: 'Trading Name',
    column_value: 'trading_name',
  },
  {
    column_name: 'Conditions',
    column_value: '',
  },
  {
    column_name: 'Capacity',
    column_value: 'capacity',
    is_bold: true,
  },
  {
    column_name: 'Status',
    column_value: 'status',
  },
  {
    column_name: 'Created Timestamp',
    column_value: 'created_at',
  },
  {
    column_name: 'Updated Timestamp',
    column_value: 'updated_at',
  },
  {
    column_name: 'Visibility',
    column_value: 'is_public',
  },
]

export const usersConfirmationsColumns = [
  {
    column_name: 'Confirmation ID',
    column_value: 'formattedId',
    is_bold: true,
  },
  {
    column_name: 'Load ID',
    column_value: 'load_id',
  },
  {
    column_name: 'Offer ID',
    column_value: 'offer_id',
  },
  {
    column_name: 'Confirmation Date',
    column_value: 'confirmedAt',
    is_bold: true,
  },
  {
    column_name: 'Loader ID',
    column_value: 'loader_id',
  },
  {
    column_name: 'Trucker ID',
    column_value: 'trucker_id',
  },
  {
    column_name: 'Capacity/Freight Method',
    column_value: 'capacity',
    is_bold: true,
  },
  {
    column_name: 'Business Name',
    column_value: 'loader_business_name',
  },
  {
    column_name: 'Business Name',
    column_value: 'trucker_business_name',
  },
  {
    column_name: 'Loading Points',
    column_value: 'loading_place_name',
    is_bold: true,
  },
  {
    column_name: 'Trading Name',
    column_value: 'loader_trading_name',
  },
  {
    column_name: 'Trading Name',
    column_value: 'trucker_trading_name',
  },
  {
    column_name: 'Unloading Points',
    column_value: 'unloading_place_name',
    is_bold: true,
  },
  {
    column_name: 'Primary Name',
    column_value: 'primary_fullname',
  },

  {
    column_name: 'Owner Name',
    column_value: 'owner_fullname',
  },
  {
    column_name: 'Truck No.',
    column_value: 'truck_number',
    is_bold: true,
  },
  {
    column_name: 'Primary Mobile No.',
    column_value: 'primary_mobiles',
  },
  {
    column_name: 'Owner Mobile No.',
    column_value: 'owner_mobile',
  },
  {
    column_name: 'Tyres',
    column_value: 'tyres',
  },
  {
    column_name: 'Alternate Name',
    column_value: 'alternative_fullname',
  },
  {
    column_name: 'Driver Name',
    column_value: 'driver_fullname',
  },
  {
    column_name: 'FR + PR + OR',
    column_value: 'fr_pr_or',
  },
  {
    column_name: 'Alternate Mobile No.',
    column_value: 'alternative_mobiles',
  },
  {
    column_name: 'Driver Mobile No.',
    column_value: 'driver_mobiles',
  },
  {
    column_name: 'Adv + To Pay + POD',
    column_value: 'delivery',
  },
  {
    column_name: 'Loading Cluster',
    column_value: 'loading_cluster',
  },
  {
    column_name: 'Offer Cluster',
    column_value: 'clusters_place_name',
  },
  {
    column_name: 'Lead Distance',
    column_value: 'lead_distance',
  },
  {
    column_name: 'Total Distance',
    column_value: 'total_distance',
  },
  {
    column_name: 'Targeted Route',
    column_value: 'targetedRoute',
  },
  {
    column_name: 'Created Timestamp',
    column_value: 'createdAt',
  },
  {
    column_name: 'Industry',
    column_value: 'industry',
  },
  {
    column_name: 'Status',
    column_value: 'status',
  },
  {
    column_name: 'Updated Timestamp',
    column_value: 'updatedAt',
  },
  {
    column_name: 'Materials / Products',
    column_value: 'products',
  },

  {
    column_name: 'Remarks',
    column_value: 'remark',
    condition: (column_name: string, column_value: string) => column_name === 'Status' && column_value === 'Cancelled',
  },
]

export const podColumns = [
  {
    column_name: 'Confirmation ID',
    column_value: 'formattedId',
    is_bold: true,
  },
  {
    column_name: 'Load ID',
    column_value: 'load',
  },
  {
    column_name: 'Offer ID',
    column_value: 'offer',
  },
  {
    column_name: 'Confirmation Date',
    column_value: 'confirmedDate',
    is_bold: true,
  },
  {
    column_name: 'Loader ID',
    column_value: 'loader_id',
  },
  {
    column_name: 'Trucker ID',
    column_value: 'trucker_id',
  },
  {
    column_name: 'Capacity/Freight Method',
    column_value: 'capacity',
    is_bold: true,
  },
  {
    column_name: 'Business Name',
    column_value: 'loader_business_name',
  },
  {
    column_name: 'Business Name',
    column_value: 'trucker_business_name',
  },
  {
    column_name: 'Loading Points',
    column_value: 'loading_place_name',
    is_bold: true,
  },
  {
    column_name: 'Trading Name',
    column_value: 'loader_trading_name',
  },
  {
    column_name: 'Trading Name',
    column_value: 'trucker_trading_name',
  },
  {
    column_name: 'Unloading Points',
    column_value: 'unloading_place_name',
    is_bold: true,
  },
  {
    column_name: 'Primary Name',
    column_value: 'loader_primary_fullname',
  },

  {
    column_name: 'Owner Name',
    column_value: 'trucker_owner_fullname',
  },
  {
    column_name: 'Truck No.',
    column_value: 'truck_number',
    is_bold: true,
  },
  {
    column_name: 'Primary Mobile No.',
    column_value: 'primary_mobiles',
  },
  {
    column_name: 'Owner Mobile No.',
    column_value: 'owner_mobile',
  },
  {
    column_name: 'Tyres',
    column_value: 'tyres',
  },
  {
    column_name: 'Alternate Name',
    column_value: 'alternative_fullname',
  },
  {
    column_name: 'Driver Name',
    column_value: 'driver_fullname',
  },
  {
    column_name: 'FR + PR + OR',
    column_value: 'fr_pr_or',
  },
  {
    column_name: 'Alternate Mobile No.',
    column_value: 'alternative_mobiles',
  },
  {
    column_name: 'Driver Mobile No.',
    column_value: 'driver_mobiles',
  },
  {
    column_name: 'Adv + To Pay + POD',
    column_value: 'paymentAmount',
  },
  {
    column_name: 'Loading Cluster',
    column_value: 'loading_cluster',
  },
  {
    column_name: 'Offer Cluster',
    column_value: 'clusters_place_name',
  },
  {
    column_name: 'Lead Distance',
    column_value: 'lead_distance',
  },
  {
    column_name: 'Total Distance',
    column_value: 'total_distance',
  },
  {
    column_name: 'Targeted Route',
    column_value: 'targetedRoute',
  },
  {
    column_name: 'Created Timestamp',
    column_value: 'createdDate',
  },
  {
    column_name: 'Industry',
    column_value: 'industry',
  },
  {
    column_name: 'Status',
    column_value: 'status',
  },
  {
    column_name: 'Updated Timestamp',
    column_value: 'updatedDate',
  },
  {
    column_name: 'Materials / Products',
    column_value: 'products',
  },
  {
    column_name: 'POD Status',
    column_value: 'podStatus',
  },
]

export const loaderConfirmationsColumns = [
  {
    column_name: 'Confirmation ID',
    column_value: 'formattedId',
    is_bold: true,
  },
  {
    column_name: 'Load ID',
    column_value: 'load_id',
  },
  {
    column_name: 'Offer ID',
    column_value: 'offer_id',
  },
  {
    column_name: 'Confirmation Date',
    column_value: 'confirmedAt',
    is_bold: true,
  },
  {
    column_name: 'Loader ID',
    column_value: 'loader_id',
  },
  {
    column_name: 'Trucker ID',
    column_value: 'trucker_id',
  },
  {
    column_name: 'Capacity/Freight Method',
    column_value: 'capacity',
    is_bold: true,
  },
  {
    column_name: 'Business Name',
    column_value: 'loader_business_name',
  },
  {
    column_name: 'Business Name',
    column_value: 'trucker_business_name',
  },
  {
    column_name: 'Loading Points',
    column_value: 'loading_place_name',
    is_bold: true,
  },
  {
    column_name: 'Trading Name',
    column_value: 'loader_trading_name',
  },
  {
    column_name: 'Trading Name',
    column_value: 'trucker_trading_name',
  },
  {
    column_name: 'Unloading Points',
    column_value: 'unloading_place_name',
    is_bold: true,
  },
  {
    column_name: 'Primary Name',
    column_value: 'primary_fullname',
  },

  {
    column_name: 'Owner Name',
    column_value: 'owner_fullname',
  },
  {
    column_name: 'Truck No.',
    column_value: 'truck_number',
    is_bold: true,
  },
  {
    column_name: 'Primary Mobile No.',
    column_value: 'primary_mobiles',
  },
  {
    column_name: 'Owner Mobile No.',
    column_value: 'owner_mobile',
  },
  {
    column_name: 'Tyres',
    column_value: 'tyres',
  },
  {
    column_name: 'Alternate Name',
    column_value: 'alternative_fullname',
  },
  {
    column_name: 'Driver Name',
    column_value: 'driver_fullname',
  },
  {
    column_name: 'FR + OR',
    column_value: 'fr_pr_or',
  },
  {
    column_name: 'Alternate Mobile No.',
    column_value: 'alternative_mobiles',
  },
  {
    column_name: 'Driver Mobile No.',
    column_value: 'driver_mobiles',
  },
  {
    column_name: 'Adv + To Pay + POD',
    column_value: 'delivery',
  },
  {
    column_name: 'Loading Cluster',
    column_value: 'loading_cluster',
  },
  {
    column_name: 'Offer Cluster',
    column_value: 'clusters_place_name',
  },
  {
    column_name: 'Lead Distance',
    column_value: 'lead_distance',
  },
  {
    column_name: 'Total Distance',
    column_value: 'total_distance',
  },
  {
    column_name: 'Targeted Route',
    column_value: 'targetedRoute',
  },
  {
    column_name: 'Created Timestamp',
    column_value: 'createdAt',
  },
  {
    column_name: 'Industry',
    column_value: 'industry',
  },
  {
    column_name: 'Status',
    column_value: 'status',
  },
  {
    column_name: 'Updated Timestamp',
    column_value: 'updatedAt',
  },
  {
    column_name: 'Materials / Products',
    column_value: 'products',
  },
  {
    column_name: 'Remarks',
    column_value: 'remark',
    condition: (column_name: string, column_value: string) => column_name === 'Status' && column_value === 'Cancelled',
  },
]

export const truckerConfirmationsColumns = [
  {
    column_name: 'Confirmation ID',
    column_value: 'formattedId',
    is_bold: true,
  },
  {
    column_name: 'Load ID',
    column_value: 'load_id',
  },
  {
    column_name: 'Offer ID',
    column_value: 'offer_id',
  },
  {
    column_name: 'Confirmation Date',
    column_value: 'confirmedAt',
    is_bold: true,
  },
  {
    column_name: 'Loader ID',
    column_value: 'loader_id',
  },
  {
    column_name: 'Trucker ID',
    column_value: 'trucker_id',
  },
  {
    column_name: 'Capacity/Freight Method',
    column_value: 'capacity',
    is_bold: true,
  },
  {
    column_name: 'Business Name',
    column_value: 'loader_business_name',
  },
  {
    column_name: 'Business Name',
    column_value: 'trucker_business_name',
  },
  {
    column_name: 'Loading Points',
    column_value: 'loading_place_name',
    is_bold: true,
  },
  {
    column_name: 'Trading Name',
    column_value: 'loader_trading_name',
  },
  {
    column_name: 'Trading Name',
    column_value: 'trucker_trading_name',
  },
  {
    column_name: 'Unloading Points',
    column_value: 'unloading_place_name',
    is_bold: true,
  },
  {
    column_name: 'Primary Name',
    column_value: 'primary_fullname',
  },

  {
    column_name: 'Owner Name',
    column_value: 'owner_fullname',
  },
  {
    column_name: 'Truck No.',
    column_value: 'truck_number',
    is_bold: true,
  },
  {
    column_name: 'Primary Mobile No.',
    column_value: 'primary_mobiles',
  },
  {
    column_name: 'Owner Mobile No.',
    column_value: 'owner_mobile',
  },
  {
    column_name: 'Tyres',
    column_value: 'tyres',
  },
  {
    column_name: 'Alternate Name',
    column_value: 'alternative_fullname',
  },
  {
    column_name: 'Driver Name',
    column_value: 'driver_fullname',
  },
  {
    column_name: 'FR + Crossing',
    column_value: 'fr_pr_or',
  },
  {
    column_name: 'Alternate Mobile No.',
    column_value: 'alternative_mobiles',
  },
  {
    column_name: 'Driver Mobile No.',
    column_value: 'driver_mobiles',
  },
  {
    column_name: 'Adv + To Pay + POD',
    column_value: 'delivery',
  },
  {
    column_name: 'Loading Cluster',
    column_value: 'loading_cluster',
  },
  {
    column_name: 'Offer Cluster',
    column_value: 'clusters_place_name',
  },
  {
    column_name: 'Lead Distance',
    column_value: 'lead_distance',
  },
  {
    column_name: 'Total Distance',
    column_value: 'total_distance',
  },
  {
    column_name: 'Targeted Route',
    column_value: 'targetedRoute',
  },
  {
    column_name: 'Created Timestamp',
    column_value: 'createdAt',
  },
  {
    column_name: 'Industry',
    column_value: 'industry',
  },
  {
    column_name: 'Status',
    column_value: 'status',
  },
  {
    column_name: 'Updated Timestamp',
    column_value: 'updatedAt',
  },
  {
    column_name: 'Materials / Products',
    column_value: 'products',
  },
  {
    column_name: 'Remarks',
    column_value: 'remark',
    condition: (column_name: string, column_value: string) => column_name === 'Status' && column_value === 'Cancelled',
  },
]

export const usersLoadsColumns = [
  {
    column_name: 'Load ID',
    column_value: 'id',
  },
  {
    column_name: 'Business Area',
    column_value: 'loaders_place_name',
    is_bold: true,
  },
  {
    column_name: 'Business Name',
    column_value: 'loaders_business_name',
  },
  {
    column_name: 'Loader ID',
    column_value: 'loader_id',
  },
  {
    column_name: 'Loading Cluster',
    column_value: 'clusters_place_name',
    is_bold: true,
  },
  {
    column_name: 'Trading Name',
    column_value: 'loaders_trading_name',
  },
  {
    column_name: 'Pri Name',
    column_value: '',
  },
  {
    column_name: 'Alt Name',
    column_value: '',
  },

  {
    column_name: 'FR + PR + OR',
    column_value: 'fr_pr_or',
  },
  {
    column_name: 'Pri Mobile No.',
    column_value: '',
  },
  {
    column_name: 'Alt Mobile No.',
    column_value: '',
  },
  {
    column_name: 'Adv + To Pay + POD',
    column_value: 'delivery',
  },
  {
    column_name: 'Loading Mobile No.',
    column_value: '',
  },
  {
    column_name: 'Loading Charges',
    column_value: '',
  },
  {
    column_name: 'Loading Points',
    column_value: '',
    is_bold: true,
  },
  {
    column_name: 'Unloading Mobile No.',
    column_value: '',
  },
  {
    column_name: 'Unloading Charges',
    column_value: '',
  },
  {
    column_name: 'Unloading Points',
    column_value: '',
    is_bold: true,
  },
  {
    column_name: 'Conditions',
    column_value: 'conditions',
  },
  {
    column_name: 'Freight Method',
    column_value: 'freight_method',
  },
  {
    column_name: 'Capacity',
    column_value: 'capacity',
    is_bold: true,
  },
  {
    column_name: 'Industry',
    column_value: 'load_industry',
  },
  {
    column_name: 'Materials / Products',
    column_value: 'load_material',
  },
  {
    column_name: 'Loading Date',
    column_value: 'loading_date',
    is_bold: true,
  },
  {
    column_name: 'Created Timestamp',
    column_value: 'created_at',
  },
  {
    column_name: 'Updated Timestamp',
    column_value: 'updated_at',
  },
  {
    column_name: 'Status',
    column_value: 'status',
  },
  {
    column_name: 'Targeted Route',
    column_value: 'targeted_route',
  },
  {
    column_name: 'Total Distance',
    column_value: 'total_distance',
  },
  {
    column_name: 'Visibility',
    column_value: 'is_public',
  },
  {
    column_name: 'Remarks',
    column_value: 'remark',
    condition: (column_name: string, column_value: string) => column_name === 'Status' && column_value === 'Cancelled',
  },
  {
    column_name: '',
    column_value: '',
    condition: (column_name: string, column_value: string) => column_name === 'Status' && column_value === 'Cancelled',
  },
  {
    column_name: '',
    column_value: '',
    condition: (column_name: string, column_value: string) => column_name === 'Status' && column_value === 'Cancelled',
  },
]

export const loadersLoadsColumns = [
  {
    column_name: 'Load ID',
    column_value: 'id',
  },
  {
    column_name: 'Business Area',
    column_value: 'loaders_place_name',
    is_bold: true,
  },
  {
    column_name: 'Business Name',
    column_value: 'loaders_business_name',
  },
  {
    column_name: 'Loader ID',
    column_value: 'loader_id',
  },
  {
    column_name: 'Loading Cluster',
    column_value: 'clusters_place_name',
    is_bold: true,
  },
  {
    column_name: 'Trading Name',
    column_value: 'loaders_trading_name',
  },
  {
    column_name: 'Pri Name',
    column_value: '',
  },
  {
    column_name: 'Alt Name',
    column_value: '',
  },

  {
    column_name: 'FR + PR + OR',
    column_value: 'fr_pr_or',
  },
  {
    column_name: 'Pri Mobile No.',
    column_value: '',
  },
  {
    column_name: 'Alt Mobile No.',
    column_value: '',
  },
  {
    column_name: 'Adv + To Pay + POD',
    column_value: 'delivery',
  },
  {
    column_name: 'Loading Mobile No.',
    column_value: '',
  },
  {
    column_name: 'Loading Charges',
    column_value: '',
  },
  {
    column_name: 'Loading Points',
    column_value: '',
    is_bold: true,
  },
  {
    column_name: 'Unloading Mobile No.',
    column_value: '',
  },
  {
    column_name: 'Unloading Charges',
    column_value: '',
  },
  {
    column_name: 'Unloading Points',
    column_value: '',
    is_bold: true,
  },
  {
    column_name: 'Conditions',
    column_value: 'conditions',
  },
  {
    column_name: 'Freight Method',
    column_value: 'freight_method',
  },
  {
    column_name: 'Capacity',
    column_value: 'capacity',
    is_bold: true,
  },
  {
    column_name: 'Industry',
    column_value: 'load_industry',
  },
  {
    column_name: 'Materials / Products',
    column_value: 'load_material',
  },
  {
    column_name: 'Loading Date',
    column_value: 'loading_date',
    is_bold: true,
  },
  {
    column_name: 'Created Timestamp',
    column_value: 'created_at',
  },
  {
    column_name: 'Updated Timestamp',
    column_value: 'updated_at',
  },
  {
    column_name: 'Status',
    column_value: 'status',
  },
  {
    column_name: 'Remarks',
    column_value: 'remark',
    condition: (column_name: string, column_value: string) => column_name === 'Status' && column_value === 'Cancelled',
  },
  {
    column_name: '',
    column_value: '',
  },
  {
    column_name: '',
    column_value: '',
  },
]

export const usersDriverColumns = [
  {
    column_name: 'ID',
    column_value: 'id',
  },
  {
    column_name: 'Name',
    column_value: 'fullname',
  },
  {
    column_name: 'Mobile No.',
    column_value: 'mobiles',
  },
  {
    column_name: 'Driver\'s License',
    column_value: 'license_number',
  },
  {
    column_name: 'Landline No.',
    column_value: 'landlines',
  },
  {
    column_name: 'Email Address',
    column_value: 'email',
  },
  {
    column_name: 'Designation',
    column_value: 'designation',
  },
  {
    column_name: 'Doc. Verification',
    column_value: 'is_docs_verified',
  },
  {
    column_name: 'Status',
    column_value: 'is_active',
  },
  {
    column_name: 'Created Timestamp',
    column_value: 'created_at',
  },
  {
    column_name: 'Updated Timestamp',
    column_value: 'updated_at',
  },
  {
    column_name: '',
    column_value: '',
  },
]

export const usersBranchesColumns = [
  {
    column_name: 'ID',
    column_value: 'id',
  },
  {
    column_name: 'Business Name',
    column_value: 'business_name',
  },
  {
    column_name: 'Trading Name',
    column_value: 'trading_name',
  },
  {
    column_name: 'Name',
    column_value: 'fullname',
  },
  {
    column_name: 'Designation',
    column_value: 'designation',
  },
  {
    column_name: 'Area',
    column_value: '',
  },
  {
    column_name: 'Mobile No.',
    column_value: 'mobiles',
  },
  {
    column_name: 'Business GST no.',
    column_value: 'email',
  },
  {
    column_name: 'Hamali Charges',
    column_value: '',
  },
  {
    column_name: 'Landline No.',
    column_value: 'landlines',
  },
  {
    column_name: 'PAN No.',
    column_value: 'pan',
  },
  {
    column_name: 'Doc Verification',
    column_value: 'is_docs_verified',
  },
  {
    column_name: 'Email Address',
    column_value: 'email',
  },
  {
    column_name: 'Website',
    column_value: '',
  },
  {
    column_name: 'Status',
    column_value: 'is_active',
  },
  {
    column_name: 'Created Timestamp',
    column_value: 'created_at',
  },
  {
    column_name: 'Updated Timestamp',
    column_value: 'updated_at',
  },
  {
    column_name: '',
    column_value: '',
  },
]

export const usersEmployeesColumns = [
  {
    column_name: 'ID',
    column_value: 'id',
  },
  {
    column_name: 'Name',
    column_value: 'fullname',
  },
  {
    column_name: 'Designation',
    column_value: 'designation',
  },
  {
    column_name: 'Mobile No.',
    column_value: 'mobiles',
  },
  {
    column_name: 'Landline No.',
    column_value: 'landlines',
  },
  {
    column_name: 'Email Address',
    column_value: 'email',
  },

  {
    column_name: 'Created Timestamp',
    column_value: 'created_at',
  },
  {
    column_name: 'Updated Timestamp',
    column_value: 'updated_at',
  },
  {
    column_name: 'Doc Verification',
    column_value: 'is_docs_verified',
  },
  {
    column_name: '',
    column_value: '',
  },
  {
    column_name: '',
    column_value: '',
  },
  {
    column_name: 'Status',
    column_value: 'is_active',
  },
]

export const columnByRole = {
  loader: loaderConfirmationsColumns,
  trucker: truckerConfirmationsColumns,
  admin: usersConfirmationsColumns,
}

export const AddOfferColumns = [
  { name: 'Business Name:', value: 'business_name' },
  { name: 'Business Area:', value: 'place_name' },
  { name: 'Trading Name:', value: 'trading_name' },
  { name: 'PAN No.:', value: 'pan' },
  { name: 'Business GST IN:', value: 'gst' },
  { name: 'Address:', value: 'address' },
  { name: 'Website:', value: 'web' },
  { name: '', value: '' },
  { name: '', value: '' },
]

export const AddLoadColumns = [
  { name: 'Loader ID:', value: 'id' },
  { name: 'Business Name:', value: 'business_name' },
  { name: 'Trading Name:', value: 'trading_name' },
  { name: 'Industry:', value: 'industry' },
  { name: 'Business Area:', value: 'place_name' },
  { name: 'Business GST IN:', value: 'gst' },
  { name: 'Materials/Product:', value: 'product' },
  { name: 'Email ID:', value: 'email' },
  { name: 'Website:', value: 'web' },
]

export const LoadBoardColumns = [
  {
    column_name: 'Load ID',
    column_value: 'id',
  },
  {
    column_name: 'Business Area',
    column_value: '',
    is_bold: true,
  },
  {
    column_name: 'Business Name',
    column_value: '',
  },
  {
    column_name: 'Loader ID',
    column_value: 'loader_id',
  },
  {
    column_name: 'Loading Cluster',
    column_value: '',
    is_bold: true,
  },
  {
    column_name: 'Trading Name',
    column_value: 'loaders_trading_name',
  },
  {
    column_name: 'Pri Name',
    column_value: 'primary_fullname',
  },
  {
    column_name: 'Alt Name',
    column_value: 'alternative_fullname',
  },
  {
    column_name: 'FR + PR + OR',
    column_value: 'fr_pr_or',
  },
  {
    column_name: 'Pri Mobile No.',
    column_value: '',
  },
  {
    column_name: 'Alt Mobile No.',
    column_value: '',

  },
  {
    column_name: 'Adv + To Pay + POD',
    column_value: 'delivery',
  },
  {
    column_name: 'Loading Mobile No.',
    column_value: '',
  },
  {
    column_name: 'Loading Charges',
    column_value: '',
  },
  {
    column_name: 'Loading Points',
    column_value: '',
    is_bold: true,
  },
  {
    column_name: 'Unloading Mobile No.',
    column_value: '',
  },
  {
    column_name: 'Unloading Charges',
    column_value: '',
  },
  {
    column_name: 'Unloading Points',
    column_value: '',
    is_bold: true,
  },
  {
    column_name: 'Conditions',
    column_value: 'conditions',
  },
  {
    column_name: 'Freight Method',
    column_value: 'freight_method',
  },
  {
    column_name: 'Capacity',
    column_value: 'capacity',
    is_bold: true,
  },
  {
    column_name: 'Industry',
    column_value: 'load_industry',
  },
  {
    column_name: 'Materials / Products',
    column_value: 'load_material',
  },
  {
    column_name: 'Loading Date',
    column_value: 'loading_date',
    is_bold: true,
  },
  {
    column_name: 'Created Timestamp',
    column_value: 'created_at',
  },
  {
    column_name: 'Updated Timestamp',
    column_value: 'updated_at',
  },
  {
    column_name: 'Status',
    column_value: 'status',
  },
  {
    column_name: 'Targeted Route',
    column_value: 'targeted_route',
  },
  {
    column_name: 'Total Distance',
    column_value: 'total_distance',
  },
  {
    column_name: 'Visibility',
    column_value: 'is_public',
  },
  {
    column_name: 'Remarks',
    column_value: 'remark',
    condition: (column_name: string, column_value: string) => column_name === 'Status' && column_value === 'Cancelled',
  },
  {
    column_name: '',
    column_value: '',
  },
  {
    column_name: '',
    column_value: '',
  },
]

export const confirmationsInvoiceColumns = [
  {
    column_name: 'Invoice No.',
    column_value: 'invoiceNo',
  },
  {
    column_name: 'Document Type',
    column_value: 'invoiceType',
  },
  {
    column_name: 'Payer',
    column_value: '',
  },
  {
    column_name: 'Consignor Business Name',
    column_value: 'consignorBusinessName',
  },
  {
    column_name: 'Area',
    column_value: 'consignorArea',
  },
  {
    column_name: 'Name',
    column_value: 'consignorName',
  },
  {
    column_name: 'Consignee Business Name',
    column_value: 'consignee',
  },
  {
    column_name: 'Area',
    column_value: 'consignee_area',
  },
  {
    column_name: 'Name',
    column_value: 'consignee_name',
  },
  {
    column_name: 'Capacity',
    column_value: 'consignee_capacity',
  },
  {
    column_name: 'Material',
    column_value: 'unLoadMaterial',
  },
  {
    column_name: 'Freight',
    column_value: 'freight',
  },
  {
    column_name: 'Created Timestamp',
    column_value: 'createdDate',
  },
  {
    column_name: 'Updated Timestamp',
    column_value: 'updatedDate',
  },
  {
    column_name: '',
    column_value: '',
  },
]

export const LoaderLoadBoardColumns = [
  {
    column_name: 'Load ID',
    column_value: 'id',
  },
  {
    column_name: 'Business Area',
    column_value: '',
    is_bold: true,
  },
  {
    column_name: 'Business Name',
    column_value: '',
  },
  {
    column_name: 'Loader ID',
    column_value: 'loader_id',
  },
  {
    column_name: 'Loading Cluster',
    column_value: '',
    is_bold: true,
  },
  {
    column_name: 'Trading Name',
    column_value: 'loaders_trading_name',
  },
  {
    column_name: 'Pri Name',
    column_value: 'primary_fullname',
  },
  {
    column_name: 'Alt Name',
    column_value: 'alternative_fullname',
  },
  {
    column_name: 'FR + PR + OR',
    column_value: 'fr_pr_or',
  },
  {
    column_name: 'Pri Mobile No.',
    column_value: '',
  },
  {
    column_name: 'Alt Mobile No.',
    column_value: '',

  },
  {
    column_name: 'Adv + To Pay + POD',
    column_value: 'delivery',
  },
  {
    column_name: 'Loading Mobile No.',
    column_value: '',
  },
  {
    column_name: 'Loading Charges',
    column_value: '',
  },
  {
    column_name: 'Loading Points',
    column_value: '',
    is_bold: true,
  },
  {
    column_name: 'Unloading Mobile No.',
    column_value: '',
  },
  {
    column_name: 'Unloading Charges',
    column_value: '',
  },
  {
    column_name: 'Unloading Points',
    column_value: '',
    is_bold: true,
  },
  {
    column_name: 'Conditions',
    column_value: 'conditions',
  },
  {
    column_name: 'Freight Method',
    column_value: 'freight_method',
  },
  {
    column_name: 'Capacity',
    column_value: 'capacity',
    is_bold: true,
  },
  {
    column_name: 'Industry',
    column_value: 'load_industry',
  },
  {
    column_name: 'Materials / Products',
    column_value: 'load_material',
  },
  {
    column_name: 'Loading Date',
    column_value: 'loading_date',
    is_bold: true,
  },
  {
    column_name: 'Created Timestamp',
    column_value: 'created_at',
  },
  {
    column_name: 'Updated Timestamp',
    column_value: 'updated_at',
  },
  {
    column_name: 'Status',
    column_value: 'status',
  },
  {
    column_name: 'Remarks',
    column_value: 'remark',
    condition: (column_name: string, column_value: string) => column_name === 'Status' && column_value === 'Cancelled',
  },
  {
    column_name: '',
    column_value: '',
  },
  {
    column_name: '',
    column_value: '',
  },
]

export const adminLoadSuggestionsColumns = [
  {
    column_name: 'Load ID',
    column_value: 'id',
  },
  {
    column_name: 'Business Area',
    column_value: '',
    is_bold: true,
  },
  {
    column_name: 'Business Name',
    column_value: '',
  },
  {
    column_name: 'Loader ID',
    column_value: 'loader_id',
  },
  {
    column_name: 'Loading Cluster',
    column_value: '',
    is_bold: true,
  },
  {
    column_name: 'Trading Name',
    column_value: 'loaders_trading_name',
  },
  {
    column_name: 'Pri Name',
    column_value: 'primary_fullname',
  },
  {
    column_name: 'Alt Name',
    column_value: 'alternative_fullname',
  },
  {
    column_name: 'FR + PR + OR',
    column_value: 'fr_pr_or',
  },
  {
    column_name: 'Pri Mobile No.',
    column_value: '',
  },
  {
    column_name: 'Alt Mobile No.',
    column_value: '',

  },
  {
    column_name: 'Adv + To Pay + POD',
    column_value: 'delivery',
  },
  {
    column_name: 'Loading Mobile No.',
    column_value: '',
  },
  {
    column_name: 'Loading Charges',
    column_value: '',
  },
  {
    column_name: 'Loading Points',
    column_value: '',
    is_bold: true,
  },
  {
    column_name: 'Unloading Mobile No.',
    column_value: '',
  },
  {
    column_name: 'Unloading Charges',
    column_value: '',
  },
  {
    column_name: 'Unloading Points',
    column_value: '',
    is_bold: true,
  },
  {
    column_name: 'Conditions',
    column_value: 'conditions',
  },
  {
    column_name: 'Freight Method',
    column_value: 'freight_method',
  },
  {
    column_name: 'Capacity',
    column_value: 'capacity',
    is_bold: true,
  },
  {
    column_name: 'Industry',
    column_value: 'load_industry',
  },
  {
    column_name: 'Materials / Products',
    column_value: 'load_material',
  },
  {
    column_name: 'Loading Date',
    column_value: 'loading_date',
    is_bold: true,
  },
  {
    column_name: 'Created Timestamp',
    column_value: 'created_at',
  },
  {
    column_name: 'Updated Timestamp',
    column_value: 'updated_at',
  },
  {
    column_name: 'Status',
    column_value: 'status',
  },
  {
    column_name: 'Targeted Route',
    column_value: 'targeted_route',
  },
  {
    column_name: 'Total Distance',
    column_value: 'total_distance',
  },
  {
    column_name: 'Visibility',
    column_value: 'is_public',
  },
  {
    column_name: '',
    column_value: '',
  },
  {
    column_name: 'Lead Distance',
    column_value: 'lead_distance',
  },
  {
    column_name: '',
    column_value: '',
  },
]

export const truckerLoadSuggestionsColumns = [
  {
    column_name: 'Load ID',
    column_value: 'id',
  },
  {
    column_name: 'Industry',
    column_value: 'load_industry',
  },
  {
    column_name: 'Materials / Products',
    column_value: 'load_material',
  },
  {
    column_name: 'Loading Cluster',
    column_value: '',
    is_bold: true,
  },
  {
    column_name: 'Loading Charges',
    column_value: '',
  },
  {
    column_name: 'Conditions',
    column_value: 'conditions',
  },
  {
    column_name: 'Unloading Points',
    column_value: '',
    is_bold: true,
  },
  {
    column_name: 'Unloading Charges',
    column_value: '',
  },
  {
    column_name: 'Capacity',
    column_value: 'capacity',
    is_bold: true,
  },
  {
    column_name: 'Freight Method',
    column_value: 'freight_method',
  },
  {
    column_name: 'FR + PR + OR',
    column_value: 'fr_pr_or',
  },
  {
    column_name: 'Adv + To Pay + POD',
    column_value: 'delivery',
  },
  {
    column_name: 'Loading Date',
    column_value: 'loading_date',
    is_bold: true,
  },
  {
    column_name: 'Lead Distance',
    column_value: 'lead_distance',
  },
  {
    column_name: 'Status',
    column_value: 'status',
  },
]

export const loaderBusinessInformationColumns = [
  { name: 'Loader ID', value: 'id' },
  { name: 'Business Name', value: 'business_name' },
  { name: 'Trading Name', value: 'trading_name' },
  { name: 'Business Area', value: 'place_name' },
  { name: 'Address', value: 'address' },
  { name: 'Business GST No.', value: 'gst' },
  { name: 'Business PAN No.', value: 'gst' },
  { name: 'Materials/Product', value: 'product' },
  { name: 'Industry', value: 'industry' },
  { name: 'Website', value: 'web' },
  { name: '', value: '' },
  { name: '', value: '' },
]

export const contactDetailsColumns = [
  { name: 'Full Name', value: 'fullname' },
  { name: 'Designation', value: 'designation' },
  { name: 'Email Address', value: 'email' },
  { name: 'Mobile No.(Primary)', value: 'mobile1' },
  { name: 'Mobile No.2', value: 'mobile2' },
  { name: 'Mobile No.3', value: 'mobile3' },
  { name: 'Landline No. (Primary)', value: 'landline1' },
  { name: 'Landline No.2', value: 'landline2' },
  { name: 'Landline No.3', value: 'landline3' },
]

export const loadInfoColumns = [
  { name: 'Capacity', value: 'capacity' },
  { name: 'Status', value: 'status' },
  { name: 'Industry', value: 'email' },
  { name: 'Loading Date', value: 'loading_date' },
  { name: 'Materials', value: 'materials' },
  { name: 'Conditions', value: 'conditions' },
]

export const offerInfoColumns = [
  { name: 'Truck No.', value: 'truck_number' },
  { name: 'Available Area', value: 'place_name' },
  { name: 'Tyres', value: 'tyres' },
  { name: 'Capacity', value: 'capacity' },
  { name: 'Engine No.', value: 'engine_number' },
  { name: 'Chassis No.', value: 'chassis_number' },
  { name: 'LWH (ft.)', value: 'lwh' },
  { name: 'Truck Available Date', value: 'loading_date' },
  { name: 'Preferred Routes', value: 'routes' },
  { name: 'Preferred Materials', value: 'materials' },
  { name: 'Conditions', value: 'conditions' },
  { name: 'Status', value: 'status' },
]

export const loadAdminFreightDetailsColumns = [
  { name: 'Freight Method', value: 'freight_method' },
  { name: 'Quantity', value: 'per_unit_quantity' },
  { name: 'Freight', value: 'freight_amount' },
  { name: 'PR', value: 'pr_amount' },
  { name: 'OR', value: 'or_amount' },
  { name: 'Total Freight', value: 'total_freight' },
]

export const loadUserFreightDetailsColumns = [
  { name: 'Freight Method', value: 'freight_method' },
  { name: 'Quantity', value: 'per_unit_quantity' },
  { name: 'Freight', value: 'freight_pr_amount' },
  { name: 'OR', value: 'or_amount' },
  { name: 'Total Freight', value: 'total_freight' },
]

export const loadPaymentStructure = [
  { title: i18n.global.t('Advance'), value: 'advance_amount' },
  { title: i18n.global.t('To Pay'), value: 'to_pay_amount' },
  { title: i18n.global.t('POD'), value: 'pod_amount' },
  { title: i18n.global.t('Total Amount'), value: 'totalAmount' },
]

export const calculatedPaymentFields = {
  admin: [
    'freightMethod',
    'freightAmount',
    'prAmount',
    'orAmount',
    'totalAmount',
  ],
  loader: [
    'freightMethod',
    'loaderFreightAmount',
    'orAmount',
    'totalAmount',
  ],
}

export const truckerBusinessInformationColumns = [
  { name: 'Trucker ID', value: 'id' },
  { name: 'Business Name', value: 'business_name' },
  { name: 'Trading Name', value: 'trading_name' },
  { name: 'Business Area', value: 'place_name' },
  { name: 'Address', value: 'address' },
  { name: 'Business GST No.', value: 'gst' },
  { name: 'Business PAN No.', value: 'gst' },
  { name: 'Website', value: 'web' },
  { name: '', value: '' },
]

export const driverContactDetailsColumns = [
  { name: 'Full Name', value: 'fullname' },
  { name: 'Designation', value: 'designation' },
  { name: 'Email Address', value: 'email' },
  { name: 'Mobile No.(Primary)', value: 'mobile1' },
  { name: 'Mobile No.2', value: 'mobile2' },
  { name: 'Mobile No.3', value: 'mobile3' },
  { name: 'Landline No. (Primary)', value: 'landline1' },
  { name: 'Landline No.2', value: 'landline2' },
  { name: 'Landline No.3', value: 'landline3' },
  { name: 'License No.', value: 'license_number' },
  { name: '', value: '' },
  { name: '', value: '' },
]
