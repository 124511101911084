import { reactive } from 'vue'

const toasterStoreActions = reactive({
  isSnackbar: false,
  toastMsg: '',
  color: '',
  timeout: 4000,
})

const toasterStoreState = {
  displaySnackbar(msg: string, snackColor: string) {
    toasterStoreActions.isSnackbar = true
    toasterStoreActions.toastMsg = msg
    toasterStoreActions.color = snackColor
  },
}

export const useToasterStore = () => ({
  state: toasterStoreState,
  actions: toasterStoreActions,
})
