<script setup lang="ts">
import { useTheme } from 'vuetify'
import { useThemeConfig } from '@core/composable/useThemeConfig'
import { hexToRgb } from '@layouts/utils'

import { usersDefaultValues } from '@/constants'
import { useMastersStore } from '@/stores/masters'
import { useToasterStore } from '@/stores/toaster'

const mastersStore = useMastersStore()

const {
  syncInitialLoaderTheme,
  syncVuetifyThemeWithTheme: syncConfigThemeWithVuetifyTheme,
  isAppRtl,
  handleSkinChanges,
} = useThemeConfig()

const { actions: toasterStoreActions } = useToasterStore()

const { global } = useTheme()

onMounted(async () => {
  // Note: below condition is to check that access token Or refresh token is expired or not
  const accessToken = localStorage.getItem('accessToken')
  const refreshToken = localStorage.getItem('refreshToken')

  if (accessToken || refreshToken)
    await mastersStore.getAllMastersData('remark,clusters,routes,industries,materials')
})

// ℹ️ Sync current theme with initial loader theme
syncInitialLoaderTheme()
syncConfigThemeWithVuetifyTheme()
handleSkinChanges()

const toastIcon = computed(() => toasterStoreActions.color === usersDefaultValues.error ? 'tabler-circle-x' : 'tabler-circle-check')
</script>

<template>
  <VLocaleProvider :rtl="isAppRtl">
    <!-- ℹ️ This is required to set the background color of active nav link based on currently active global theme's primary -->
    <VApp :style="`--v-global-theme-primary: ${hexToRgb(global.current.value.colors.primary)}`">
      <RouterView />
      <ScrollToTop />
      <VSnackbar
        v-model="toasterStoreActions.isSnackbar"
        location="top center"
        variant="flat"
        :color="toasterStoreActions.color"
        :timeout="toasterStoreActions.timeout"
      >
        <VIcon :icon="toastIcon" /> {{ toasterStoreActions.toastMsg }}
      </VSnackbar>
    </VApp>
  </VLocaleProvider>
</template>
