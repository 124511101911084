import axios from '@/plugins/axios'

export const reportService = () => {
  const getReportList = (reportsOptions: object) => {
    const queryParams = { ...reportsOptions }

    if (reportsOptions.routes)
      queryParams.routes = reportsOptions.routes.map(route => String(route)).join(',')

    return axios.get(`/v1/reports/routes`, { params: queryParams })
  }

  const exportReports = (module: string, exportFile: string, data: object) => {
    const queryParams = { ...data }

    if (data.routes)
      queryParams.routes = data.routes.map(route => String(route)).join(',')

    return axios.get(`/v1/reports/${module}/export?reportType=${exportFile}`, {
      params: queryParams,
      responseType: 'blob',
    })
  }

  const getCampaigns = (logsOf: string, params: object) => {
    return axios.get(`/v1/campaigns?messageType=${logsOf}`, { params })
  }

  const getLogs = (logsOf: string, campaignId: string, params: object) => {
    return axios.get(`/v1/reports/${logsOf}?campaignId=${campaignId}`, { params })
  }

  const exportMessageReports = (module: string, exportFile: string, campaignId: string, data: object) => {
    return axios.get(`/v1/reports/${module}/export?reportType=${exportFile}&campaignId=${campaignId}`, {
      params: data,
      responseType: 'blob',
    })
  }

  const exportCampaignsReports = (module: string, data: object) => {
    return axios.get(`/v1/campaigns/export?messageType=${module}`, {
      params: data,
      responseType: 'blob',
    })
  }

  return {
    getReportList, getCampaigns, getLogs, exportReports, exportMessageReports, exportCampaignsReports,
  }
}
