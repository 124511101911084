import type { IAdminDetail } from '@/interface'
import i18n from '@/plugins/i18n/index'

export const registerInfo = reactive(
  {
    business_name: '',
    email: '',
    fullname: '',
    password: '',
    password_confirmation: '',
    phone: null,
    trading_name: '',
    type: '',
    device_name: 'web',
  })

export const loginType = {
  password: 'password',
  otp: 'otp',
}

export const passwordRequirements = [
  'Minimum 8 characters long - the more, the better',
  'At least one lowercase character',
  'At least one number, symbol, or whitespace character',
]

export const loginOptions = {
  password: i18n.global.t('login.type_login'),
  otp: i18n.global.t('login.type_otp'),
}

export const registerType = {
  trucker: i18n.global.t('trucker.type'),
  loader: i18n.global.t('loader.type'),
}

export const registerUser = [
  { name: 'Trucker', value: 'trucker' },
  { name: 'Loader', value: 'loader' },
]

export const adminDetails: IAdminDetail = reactive({
  id: 0,
  uuid: '',
  fullname: '',
  email: '',
  phone: '',
  mobiles: [],
  landlines: [],
})
